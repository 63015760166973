import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Button,
    Heading,
} from '@chakra-ui/react'
import {useContext, useState} from "react";
import Cookies from "js-cookie";
import {UserContext} from "../../hooks/context";
import Environment from "../../utils/environment";
import {useCustomToast} from "../../utils/toast";
import UserAPI from "../../api/userApi";

const Login = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const userContext = useContext(UserContext)

    const toast = useCustomToast()

    const logOut = () => {
        Cookies.remove('accessToken')
        userContext?.setUser(null)
    }

    if (userContext?.user !== null) {
        return (
            <div>
                <Heading size={'lg'}>
                    Already Signed In
                </Heading>
                <br />
                <br />
                <Button
                    bg={'blue.400'}
                    color={'white'}
                    onClick={logOut}
                    _hover={{
                        bg: 'blue.500',
                    }}>
                    Sign Out
                </Button>
            </div>
        )
    }

    const onSignIn = () => {
        UserAPI
            .login(username, password)
            .then(res => {
                Cookies.set('accessToken', res.accessToken, {
                    expires: 31,
                    secure: true,
                })
                userContext?.setUser(res.user)
            })
            .then(() => window.location.href = `/${Environment.adminRoute}`)
            .catch(err => {
                console.log({err})
                toast({
                    title: 'Error',
                    description: 'Wrong username and/or password.',
                    status: "error",
                })
            })
    }

    return (
        <Flex
            justify={'center'}
            bg={'gray.800'}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Stack align={'center'}>
                    <Heading fontSize={'4xl'}>Admin Log In</Heading>
                </Stack>
                <Box
                    rounded={'lg'}
                    bg={'gray.700'}
                    boxShadow={'lg'}
                    p={8}>
                    <FormControl>
                        <FormLabel>Username</FormLabel>
                        <Input value={username}
                               onChange={e => setUsername(e.target.value)} />
                        <br />
                        <br />
                        <FormLabel>Password</FormLabel>
                        <Input type="password"
                               value={password}
                               onChange={e => setPassword(e.target.value)}
                        />
                        <br />
                        <br />
                        <Button
                            bg={'blue.400'}
                            color={'white'}
                            onClick={onSignIn}
                            _hover={{
                                bg: 'blue.500',
                            }}>
                            Sign in
                        </Button>
                    </FormControl>
                </Box>
            </Stack>
        </Flex>
    )
}

export default Login
