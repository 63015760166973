import {Match} from "../../models/Models";
import {useState} from "react";
import {useCustomToast} from "../../utils/toast";
import {useMutation, useQueryClient} from "react-query";
import {TournamentAPI} from "../../api/tournamentApi";
import {
    Button,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper, Radio, RadioGroup, Stack
} from "@chakra-ui/react";

interface SubmitMatchFormProps {
    match: Match
    submitCallBack?: () => void
}

const SubmitMatchForm = (props: SubmitMatchFormProps) => {
    const { match } = props;

    const STARTING_SETS = 2

    const [sets, setSets] = useState(STARTING_SETS)

    const initialPoints = []
    for (let i = 0; i < STARTING_SETS; i++) initialPoints.push(0)

    const [teamAPoints, setTeamAPoints] = useState<number[]>(initialPoints)
    const [teamBPoints, setTeamBPoints] = useState<number[]>(initialPoints)

    const toast = useCustomToast()
    const queryClient = useQueryClient()

    let mutationFn = () => TournamentAPI
        .submitMatch(match.id, teamAPoints, teamBPoints, sets)
        .catch(err => {
            console.log({err});
            toast({
                title: 'Error',
                description: 'Error submitting match.',
                status: "error",
            })
        })
        .finally(() => {
            if (props.submitCallBack) {
                props.submitCallBack()
            }
        })

    const addScoreMutation = useMutation({
        mutationFn: mutationFn,
        onSuccess: () => queryClient.invalidateQueries(["tournament", match.tournamentId])
    })

    const onSetsChange = (newSets: string) => {
        let intSets = Number(newSets)
        let newAPoints: number[], newBPoints: number[];
        if (sets >= intSets) {
            newAPoints = teamAPoints.slice(0, intSets)
            newBPoints = teamBPoints.slice(0, intSets)
        } else {
            let diff = intSets - sets
            let newScores = Array.from(Array(diff).keys()).map(_ => 0)
            newAPoints = [...teamAPoints]
            newBPoints = [...teamBPoints]
            newAPoints.push(...newScores)
            newBPoints.push(...newScores)
        }
        setSets(intSets)
        setTeamAPoints(newAPoints)
        setTeamBPoints(newBPoints)
    }

    const setPoint = (point: number, setNum: number, team: "A"|"B") => {
        if (team === "A") {
            let newAPoints = [...teamAPoints]
            newAPoints[setNum] = point
            setTeamAPoints(newAPoints)
        } else {
            let newBPoints = [...teamBPoints]
            newBPoints[setNum] = point
            setTeamBPoints(newBPoints)
        }
    }

    const renderPoints = (team: "A"|"B") => {
        let pointsArray = teamAPoints
        if (team === "B") {
            pointsArray = teamBPoints
        }
        return Array.from(Array(sets).keys()).map(i => (
            <NumberInput step={1} value={pointsArray[i]} min={0} key={i}
                         onChange={(valueAsString, valueAsNumber) => {
                             setPoint(valueAsNumber, i, team)
                         }}>
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
        ))
    }

    return (
        <div>
            Sets
            <RadioGroup onChange={onSetsChange}
                        value={String(sets)}>
                <Stack direction={'row'}>
                    <Radio value='1'>1</Radio>
                    <Radio value='2'>2</Radio>
                    <Radio value='3'>3</Radio>
                </Stack>
            </RadioGroup>
            <br />
            <div style={{float: 'left', width: '45%'}}>
                {match.teamA.name}
                <br />
                {renderPoints("A")}
            </div>
            <div style={{float: 'right', width: '45%'}}>
                {match.teamB.name}
                <br />
                {renderPoints("B")}
            </div>
            <div style={{clear: 'both'}} />
            <br />
            <Button colorScheme='blue' onClick={() => addScoreMutation.mutate()}>
                Submit
            </Button>
        </div>
    )
}

export default SubmitMatchForm