import {Pool, Team} from "../models/Models";
import {Roster} from "../models/leagueModels";

export const getPoolsFromTeams = (teams: Team[]) => {
    let pools: Pool[] = []
    teams.forEach(team => {
        if (!pools.includes(team.pool)) {
            pools.push(team.pool)
        }
    })
    pools.sort()
    return pools
}

export const sortTeamsFn = (a: Team , b: Team) => {
    let aSeed = a.seed || 100
    let bSeed = b.seed || 100
    return aSeed - bSeed
}

export const displayName = (r: Roster, target: Roster) => {
    if (r.id === target.id) {
        return <b>{r.name}</b>
    }
    return r.name
}

