import {
    Button,
    Box,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper, VStack,
} from "@chakra-ui/react";
import {useState} from "react";
import LeagueAPI from "../../api/leagueApi";
import {useCustomToast} from "../../utils/toast";
import Environment from "../../utils/environment";
import {useNavigate} from "react-router-dom";
import {useMutation, useQueryClient} from "react-query";

interface CreateLeagueModalProps {
    isOpen: boolean
    onClose: () => void
}

const CreateLeagueModal = (props: CreateLeagueModalProps) => {
    const { isOpen, onClose } = props;
    const [name, setName] = useState("")
    const [maxTeams, setMaxTeams] = useState(0)
    const [startDate, setStartDate] = useState("2024-01-01");
    const [endDate, setEndDate] = useState("2024-01-01");

    const toast = useCustomToast()
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const adminRoute = Environment.adminRoute

    const createLeague = () => {
        return LeagueAPI.createLeague(name, maxTeams, new Date(startDate).toISOString(), new Date(endDate).toISOString())
            .then(response => {
                const leagueId = response.league.id
                navigate(`/${adminRoute}/league/${leagueId}/manage`)
            }).catch((error: any) => {
                console.log({error});
                toast({
                    title: 'Error',
                    description: 'Error creating league.',
                    status: "error",
                })
            }).finally(()=>{
                onClose()
            })
    }

    const createLeagueMutation = useMutation({
        mutationFn: () => createLeague(),
        onSuccess: () => queryClient.invalidateQueries(["league"])
    })

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Create League</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack spacing={6}>
                        <Box>
                            Name
                            <Input
                                value={name}
                                onChange={e => setName(e.target.value)}
                                placeholder='Spring League...'
                            />
                        </Box>
                        <Box>
                            Max Teams
                            <NumberInput step={1} value={maxTeams} min={0}
                                         onChange={(_, valueAsNumber) => {
                                             setMaxTeams(valueAsNumber)
                                         }}>
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                        </Box>
                        <Box>
                            Start Date {'  '}
                            <input type='date'
                                   value={startDate}
                                   onChange={e => setStartDate(e.target.value)}
                            />
                        </Box>
                        <Box>
                            End Date {'  '}
                            <input type='date'
                                   value={endDate}
                                   onChange={e => setEndDate(e.target.value)}
                            />
                        </Box>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button variant='ghost' onClick={onClose}>Cancel</Button>
                    <Button colorScheme='blue' onClick={() => createLeagueMutation.mutate()}>
                        Create
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default CreateLeagueModal