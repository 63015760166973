import {
    Box,
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Select,
    useDisclosure,
    VStack
} from "@chakra-ui/react";
import {useState} from "react";
import LeagueAPI from "../../api/leagueApi";
import {useMutation, useQueryClient} from "react-query";
import {useCustomToast} from "../../utils/toast";
import {RosterMember} from "../../models/leagueModels";

interface AddPlayerStatButtonProps {
    gameId: number
    memberOptions: RosterMember[]
    rosterName: string
}

const AddPlayerStatButton = ({ gameId, memberOptions, rosterName }: AddPlayerStatButtonProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const queryClient = useQueryClient()
    const toast = useCustomToast()

    const [kills, setKills] = useState(0)
    const [blocks, setBlocks] = useState(0)
    const [aces, setAces] = useState(0)
    const [assists, setAssists] = useState(0)

    const defaultRosterMember = memberOptions.length > 0 ? memberOptions[0].id : 0
    const [rosterMemberId, setRosterMemberId] = useState(defaultRosterMember)

    const createStat = () => {
        return LeagueAPI.createStat(
            gameId, rosterMemberId as number, kills, blocks, aces, assists
        )
            .catch((error: any) => {
                console.log({error});
                toast({
                    title: 'Error',
                    description: 'Error submitting stats.',
                    status: "error",
                })
            }).finally(()=>{
                onClose()
            })
    }

    const createStatMutation = useMutation({
        mutationFn: () => createStat(),
        onSuccess: () => queryClient.invalidateQueries(["game", gameId])
    })

    const memberOptionsNodes = memberOptions.map(m =>
        <option value={m.id} key={m.id}>[{m.jerseyNumber} - {m.jerseyName}] {m.player.firstName}</option>
    )

    return (
        <>
            <Button onClick={onOpen}>
                Add Stat - {rosterName}
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Stats</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={6}>
                            <Select placeholder='Choose Player' value={rosterMemberId}
                                    onChange={event => setRosterMemberId(Number(event.target.value))}>
                                {memberOptionsNodes}
                            </Select>
                            <Box>
                                Kills
                                <NumberInput step={1} value={kills} min={0} max={100}
                                             onChange={(_, valueAsNumber) => {
                                                 setKills(valueAsNumber)
                                             }}>
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </Box>
                            <Box>
                                Blocks
                                <NumberInput step={1} value={blocks} min={0} max={100}
                                             onChange={(_, valueAsNumber) => {
                                                 setBlocks(valueAsNumber)
                                             }}>
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </Box>
                            <Box>
                                Aces
                                <NumberInput step={1} value={aces} min={0} max={100}
                                             onChange={(_, valueAsNumber) => {
                                                 setAces(valueAsNumber)
                                             }}>
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </Box>
                            <Box>
                                Assists
                                <NumberInput step={1} value={assists} min={0} max={100}
                                             onChange={(_, valueAsNumber) => {
                                                 setAssists(valueAsNumber)
                                             }}>
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </Box>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant='ghost' onClick={onClose}>Cancel</Button>
                        {rosterMemberId !== 0 &&
                            <Button colorScheme='blue' onClick={() => createStatMutation.mutate()}>
                                Create
                            </Button>
                        }
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default AddPlayerStatButton