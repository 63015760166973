import {Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import {NestedRosterMember} from "../../models/leagueModels";
import {Link} from "react-router-dom";

interface PlayerLeaguesTableProps {
    rosterMembers: NestedRosterMember[]
}

const PlayerLeaguesTable = (props: PlayerLeaguesTableProps) => {
    const { rosterMembers } = props

    return (
        <Table>
            <Thead>
                <Tr>
                    <Th>League</Th>
                    <Th>Jersey</Th>
                    <Th>Stats</Th>
                </Tr>
            </Thead>
            <Tbody>
                {rosterMembers.map(rm => (
                    <Tr key={rm.id}>
                        <Td>{rm.roster.league.name}</Td>
                        <Td>{rm.jerseyName} ({rm.jerseyNumber})</Td>
                        <Td>
                            <Link to={`/player/${rm.playerId}/league/${rm.roster.leagueId}`} style={{
                                textDecoration:'underline',
                                color: 'lightblue'
                            }}>
                                View Stats
                            </Link>
                        </Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    )
}

export default PlayerLeaguesTable