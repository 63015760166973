import {Roster} from "../../models/leagueModels";
import {Select} from "@chakra-ui/react";

interface RosterDropdownProps {
    rosters: Roster[]
    rosterId: number
    setRosterId: (id: number) => void
}

const RosterDropdown = ({ rosters, rosterId, setRosterId }: RosterDropdownProps) => {

    const rosterOptions = rosters.map(r => (
        <option value={r.id} key={r.id}>
            {r.name}
        </option>
    ))

    return (
        <Select placeholder='Choose Roster' value={rosterId}
                onChange={event => setRosterId(Number(event.target.value))}>
            {rosterOptions}
        </Select>
    )
}

export default RosterDropdown