import {Team, Tournament} from "../../models/Models";
import {sortTeamsFn} from "../../utils/format";
import {Heading, Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";

interface DisplaySeedingProps {
    tournament: Tournament
    teams: Team[]
}

const DisplaySeeding = (props: DisplaySeedingProps) => {
    const { tournament, teams } = props
    if (
        tournament.playoffFormat !== "UNIFIED_SEEDING" ||
        tournament.stage === "PREP" || tournament.stage === "POOL"
    ) {
        return null
    }

    let sortedTeams = teams.sort(sortTeamsFn)

    return (
        <TableContainer>
            <Table variant='simple'>
                <TableCaption placement={"top"}>
                    <Heading size={'md'}>
                        Playoff Seeding
                    </Heading>
                </TableCaption>
                <Thead>
                    <Tr>
                        <Th>Seed</Th>
                        <Th>Team Name</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {sortedTeams.map(team => (
                        <Tr key={team.id}>
                            <Td>{team.seed}</Td>
                            <Td>{team.name} ({team.pool}{team.poolIndex})</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    )
}

export default DisplaySeeding