import BaseBackendAPI from "./backendApi";
import {
    Game,
    Gender,
    League,
    NestedRosterMember, Player,
    Roster,
    RosterMember,
    RosterMemberGameStats, RosterMemberIterationStats
} from "../models/leagueModels";

interface getLeaguesResponse {
    data: League[]
}

interface getLeagueResponse {
    league: League
    rosters: Roster[]
    games: Game[]
}

interface createLeagueResponse {
    league: League
}

interface createRosterResponse {
    roster: Roster
}

interface getRosterResponse {
    league: League
    roster: Roster
    rosterMembers: RosterMember[]
    games: Game[]
}

interface getGameResponse {
    league: League
    game: Game
    stats: RosterMemberGameStats[]
    rosterAMembers: RosterMember[]
    rosterBMembers: RosterMember[]
}

interface getPlayerResponse {
    player: Player
    rosterMembers: NestedRosterMember[]
}

interface createRosterMemberGameStatsResponse {
    stat: RosterMemberGameStats
}

interface createRosterMemberResponse {
    rosterMember: RosterMember
}

interface createGameResponse {
    game: Game
}

interface submitGameResponse {
    game: Game
}

interface getPlayerLeagueResponse {
    league: League
    rosterMember: RosterMember
    player: Player
    rosterMemberIterationStats: RosterMemberIterationStats[]

}

class LeagueAPI extends BaseBackendAPI {
    static getLeagues = () => {
        return this.get(`/league`)
            .then<getLeaguesResponse>(res => res.data)
    }

    static getLeague = (id: number) => {
        return this.get(`/league/${id}`)
            .then<getLeagueResponse>(res => res.data)
    }

    static createLeague = (name: string, maxTeams: number, startDate: string, endDate: string) => {
        return this.post(`/league`, {
            league: {name, maxTeams, startDate, endDate}
        })
            .then<createLeagueResponse>(res => res.data)
    }

    static createRoster = (leagueId: number, name: string, tier: number) => {
        return this.post(`/league/${leagueId}/create-roster`, {
            roster: {name, tier}
        })
            .then<createRosterResponse>(res => res.data)
    }

    static createRosterMember = (rosterId: number, firstName: string, lastName: string,
                                 gender: Gender, jerseyNumber: number, jerseyName: string) => {
        return this.post(`/roster/${rosterId}/create-player`, {
            player: {firstName, lastName, jerseyNumber, jerseyName, gender}
        })
            .then<createRosterMemberResponse>(res => res.data)
    }

    static getRoster = (rosterId: number) => {
        return this.get(`/roster/${rosterId}`)
            .then<getRosterResponse>(res => res.data)
    }

    static getGame = (gameId: number) => {
        return this.get(`/game/${gameId}`)
            .then<getGameResponse>(res => res.data)
    }

    static getPlayer = (playerId: number) => {
        return this.get(`/player/${playerId}`)
            .then<getPlayerResponse>(res => res.data)
    }

    static getPlayerLeague = (playerId: number, leagueId: number) => {
        return this.get(`/player/${playerId}/league/${leagueId}`)
            .then<getPlayerLeagueResponse>(res => res.data)
    }

    static createGame = (leagueId: number, rosterAId: number, rosterBId: number, court: string,
                         startsAt: string, refRosterId: number, iteration: number, tier: number) => {
        return this.post(`/league/${leagueId}/create-game`, {
            game: {rosterAId, rosterBId, court, startsAt, refRosterId, iteration, tier}
        })
            .then<createGameResponse>(res => res.data)
    }

    static createStat = (gameId: number,
                         rosterMemberId: number,
                         kills: number,
                         blocks: number,
                         aces: number,
                         assists: number) => {
        return this.post(`/game/${gameId}/create-stat`, {
            rosterMemberId,
            kills,
            blocks,
            aces,
            assists
        })
            .then<createRosterMemberGameStatsResponse>(res => res.data)
    }

    static generateStandings = (leagueId: number) => {
        return this.post(`/league/${leagueId}/generate-standings`, {})
    }

    static generateGames = (leagueId: number) => {
        return this.post(`/league/${leagueId}/generate-games`, {})
    }

    static endIteration = (leagueId: number) => {
        return this.post(`/league/${leagueId}/end-iteration`, {})
    }

    static submitGame = (gameId: number,
                         rosterAPoints: number[],
                         rosterBPoints: number[],
                         sets: number) => {
        const postData = {
            rosterAPoints,
            rosterBPoints,
            sets,
        }
        return this.post(`/game/${gameId}/submit`, postData)
            .then<submitGameResponse>(res => res.data)
    }

    static submitPlayerIterationStats = (rosterMemberId: number,
                                         data: { kills: number, blocks: number, aces: number, assists: number }) => {
        return this.post(`/roster-member/${rosterMemberId}/submit-iteration-stats`, data)
    }
}

export default LeagueAPI