import {useToast} from "@chakra-ui/react";

const useCustomToast = () => {
    return useToast({
        duration: 3000,
        isClosable: true
    })
}

export { useCustomToast }
