import {Match, MatchBracket, Tournament} from "../../models/Models";
import RenderBracket from "./RenderBracket";
import {useSearchParams} from "react-router-dom";
import {Heading, Radio, RadioGroup, Stack, Text} from "@chakra-ui/react";

const PlayoffBrackets = (props: PlayoffBracketsProps) => {

    let [searchParams, setSearchParams] = useSearchParams();

    let matchesDict: { [key in MatchBracket]: Match[] } = {
        'GOLD': [],
        'SILVER': [],
        'BRONZE': [],
        'CONSOLATION': []
    }

    for (const match of props.matches) {
        if (!match.bracket) {
            continue
        }
        matchesDict[match.bracket].push(match)
    }

    let numBrackets = 0
    for (const kv of Object.entries(matchesDict)) {
        let matches = kv[1]
        if (matches.length > 0) {
            numBrackets += 1
        }
    }

    if (numBrackets === 1) {
        return (
            <div>
                <Heading size={'lg'}>
                    Playoffs
                </Heading>
                <br />
                <Text>
                    Legend: WO = Walk Over / Bye
                </Text>
                <br />
                <RenderBracket tournament={props.tournament}
                               matches={matchesDict.GOLD}
                               isAdmin={props.isAdmin} />
            </div>
        )
    }

    let bracket: MatchBracket = searchParams.get('bracket') as MatchBracket
    if (!Object.keys(MatchBracket).includes(bracket)) {
        bracket = MatchBracket.GOLD
    }

    return (
        <div>
            <Heading size={'lg'}>
                Playoff Bracket
            </Heading>
            <br />
            <Text>
                Legend: WO = Walk Over / Bye
            </Text>
            <br />
            <RadioGroup value={bracket}
                        onChange={(newBracket: MatchBracket) => {
                            searchParams.set('bracket', newBracket)
                            setSearchParams(searchParams)
                        }}
            >
                <Stack direction='column' align={'center'}>
                    {matchesDict["GOLD"].length > 0 && <Radio value={MatchBracket.GOLD} size='lg'>Gold Bracket</Radio>}
                    {matchesDict["SILVER"].length > 0 && <Radio value={MatchBracket.SILVER} size='lg'>Silver Bracket</Radio>}
                    {matchesDict["BRONZE"].length > 0 && <Radio value={MatchBracket.BRONZE} size='lg'>Bronze Bracket</Radio>}
                    {matchesDict["CONSOLATION"].length > 0 && <Radio value={MatchBracket.CONSOLATION} size='lg'>Consolation Bracket</Radio>}
                </Stack>
            </RadioGroup>
            <br />
            <RenderBracket tournament={props.tournament}
                           matches={matchesDict[bracket]}
                           isAdmin={props.isAdmin} />
        </div>
    )
}

interface PlayoffBracketsProps {
    tournament: Tournament
    matches: Match[],
    isAdmin: boolean,
}

export default PlayoffBrackets