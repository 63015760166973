import BaseBackendAPI from "./backendApi";
import {AuthenticateUserResponse, LoginResponse} from "./responses";


class UserAPI extends BaseBackendAPI {
    static login = (username: string, password: string) => {
        return this.post(`/user/login`, {
            user: {
                username,
                password
            }
        })
            .then<LoginResponse>(res => res.data)

    }

    static authenticateUser = () => {
        return this.get(`/user/auth`)
            .then<AuthenticateUserResponse>(res => res.data)
    }
}

export default UserAPI