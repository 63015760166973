import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    RadioGroup,
    Stack,
    Radio,
} from "@chakra-ui/react";
import {useState} from "react";
import {TournamentAPI} from "../../api/tournamentApi";
import {useCustomToast} from "../../utils/toast";
import {useNavigate} from "react-router-dom";
import Environment from "../../utils/environment";
import {useMutation, useQueryClient} from "react-query";



interface CreateTournamentModalProps {
    isOpen: boolean
    onClose: () => void
}

const CreateTournamentModal = (props: CreateTournamentModalProps) => {
    const { isOpen, onClose } = props;

    const [createTournamentName, setName] = useState("")
    const [createTournamentTeamCount, setTeamCount] = useState(8)
    const [createTournamentPoolsCount, setPoolsCount] = useState(2)

    const toast = useCustomToast()
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const adminRoute = Environment.adminRoute

    const createTournament = () => {
        return TournamentAPI.createTournament(createTournamentName, createTournamentTeamCount, createTournamentPoolsCount)
            .then(response => {
                const tournamentId = response.tournament.id
                navigate(`/${adminRoute}/tournament/${tournamentId}/manage`)
            }).catch((error: any) => {
                console.log({error});
                toast({
                    title: 'Error',
                    description: 'Error creating tournament.',
                    status: "error",
                })
            }).finally(()=>{
                onClose()
            })
    }

    const newTournamentMutation = useMutation({
        mutationFn: () => createTournament(),
        onSuccess: () => queryClient.invalidateQueries(["tournament"])
    })

    const onFormatChange = (newVal: string) => {
        let [team, pool] = newVal.split(",")
        setTeamCount(Number(team))
        setPoolsCount(Number(pool))
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create Tournament</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Name
                        <Input
                            value={createTournamentName}
                            onChange={e => setName(e.target.value)}
                            placeholder='TMU Tourney...'
                        />
                        <br />
                        <br />
                        Format
                        <RadioGroup onChange={onFormatChange} value={`${createTournamentTeamCount},${createTournamentPoolsCount}`}>
                            <Stack>
                                <Radio value='8,1'>8 Teams, 1 Pool</Radio>
                                <Radio value='8,2'>8 Teams, 2 Pools</Radio>
                                <Radio value='9,2'>9 Teams, 2 Pools</Radio>
                                <Radio value='10,2'>10 Teams, 2 Pools</Radio>
                                <Radio value='12,2'>12 Teams, 2 Pools</Radio>
                                <Radio value='12,3'>12 Teams, 3 Pools</Radio>
                                <Radio value='12,4'>12 Teams, 4 Pools</Radio>
                                <Radio value='14,2'>14 Teams, 2 Pools</Radio>
                                <Radio value='16,4'>16 Teams, 4 Pools</Radio>
                                <Radio value='28,7'>28 Teams, 7 Pools</Radio>
                            </Stack>
                        </RadioGroup>

                    </ModalBody>
                    <ModalFooter>
                        <Button variant='ghost' onClick={onClose}>Cancel</Button>
                        <Button colorScheme='blue' onClick={() => newTournamentMutation.mutate()}>
                            Create
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
    )
}


export default CreateTournamentModal