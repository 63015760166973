import {Game, Roster} from "../../models/leagueModels";
import {Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import {formatDtStrToDate} from "../../utils/datetimeUtils";
import {Link} from "react-router-dom";

interface PastGamesTableProps {
    targetRoster: Roster
    games: Game[]
}

const PastGamesTable = ({ games, targetRoster }: PastGamesTableProps) => {
    const gameRows = games.map((game) => {
        let isA = (targetRoster.id === game.rosterAId)
        return (
            <Tr key={game.id}>
                <Td>{formatDtStrToDate(game.startsAt)}</Td>
                <Td>
                    <Link to={`/game/${game.id}`} style={{
                        textDecoration:'underline',
                        color: 'lightblue'
                    }}>
                        {isA ? game.rosterB.name : game.rosterA.name}
                    </Link>
                </Td>
                <Td>
                    {Array.from(Array(game.sets).keys()).map(i => (
                        <div key={i}>
                            {isA ? `${game.rosterAPoints[i]} - ${game.rosterBPoints[i]}`
                                : `${game.rosterBPoints[i]} - ${game.rosterAPoints[i]}`}
                            <br />
                        </div>
                    ))}
                </Td>
            </Tr>
        )
    })

    return (
        <TableContainer>
            <Table variant='simple' size={'sm'}>
                <TableCaption placement={"top"}>
                    Score format is Your Team - Opponent Team
                </TableCaption>
                <Thead>
                    <Tr>
                        <Th>Date</Th>
                        <Th>VS</Th>
                        <Th>Scores</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {gameRows}
                </Tbody>
            </Table>
        </TableContainer>
    )
}

export default PastGamesTable
