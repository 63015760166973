import {Heading} from "@chakra-ui/react";

const Unauthorized = () => {
    return (
        <Heading size={'lg'}>
            Unauthorized to access this page.
        </Heading>
    )
}

export default Unauthorized
