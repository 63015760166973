import {
    Button
} from "@chakra-ui/react";
import LeagueAPI from "../../api/leagueApi";
import {useMutation, useQueryClient} from "react-query";
import {useCustomToast} from "../../utils/toast";

interface GenerateStandingsButtonProps {
    leagueId: number
}

const GenerateStandingsButton = ({ leagueId }: GenerateStandingsButtonProps) => {
    const toast = useCustomToast()
    const queryClient = useQueryClient()

    const generateStandings = () => {
        return LeagueAPI.generateStandings(leagueId)
            .catch((error: any) => {
                console.log({error});
                toast({
                    title: 'Error',
                    description: 'Error generating standings.',
                    status: "error",
                })
            })
    }

    const generateStandingsMutation = useMutation({
        mutationFn: () => generateStandings(),
        onSuccess: () => queryClient.invalidateQueries(["league", leagueId])
    })

    return (
        <Button colorScheme={'teal'} onClick={() => generateStandingsMutation.mutate()}>
            Generate Standings
        </Button>
    )
}

export default GenerateStandingsButton