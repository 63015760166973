import {useState} from "react";
import {useCustomToast} from "../../utils/toast";
import {useMutation, useQueryClient} from "react-query";
import {
    Button,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper, Radio, RadioGroup, Stack
} from "@chakra-ui/react";
import {Game} from "../../models/leagueModels";
import LeagueAPI from "../../api/leagueApi";

interface SubmitGameFormProps {
    game: Game
    submitCallBack?: () => void
}

const SubmitGameForm = (props: SubmitGameFormProps) => {
    const { game } = props;

    const STARTING_SETS = 2

    const [sets, setSets] = useState(STARTING_SETS)

    const initialPoints = []
    for (let i = 0; i < STARTING_SETS; i++) initialPoints.push(0)

    const [rosterAPoints, setRosterAPoints] = useState<number[]>(initialPoints)
    const [rosterBPoints, setRosterBPoints] = useState<number[]>(initialPoints)

    const toast = useCustomToast()
    const queryClient = useQueryClient()

    let mutationFn = () => LeagueAPI
        .submitGame(game.id, rosterAPoints, rosterBPoints, sets)
        .catch(err => {
            console.log({err});
            toast({
                title: 'Error',
                description: 'Error submitting game.',
                status: "error",
            })
        })
        .finally(() => {
            if (props.submitCallBack) {
                props.submitCallBack()
            }
        })

    const submitGameMutation = useMutation({
        mutationFn: mutationFn,
        onSuccess: () => queryClient.invalidateQueries(["league", game.leagueId])
    })

    const onSetsChange = (newSets: string) => {
        let intSets = Number(newSets)
        let newAPoints: number[], newBPoints: number[];
        if (sets >= intSets) {
            newAPoints = rosterAPoints.slice(0, intSets)
            newBPoints = rosterBPoints.slice(0, intSets)
        } else {
            let diff = intSets - sets
            let newScores = Array.from(Array(diff).keys()).map(_ => 0)
            newAPoints = [...rosterAPoints]
            newBPoints = [...rosterBPoints]
            newAPoints.push(...newScores)
            newBPoints.push(...newScores)
        }
        setSets(intSets)
        setRosterAPoints(newAPoints)
        setRosterBPoints(newBPoints)
    }

    const setPoint = (point: number, setNum: number, team: "A"|"B") => {
        if (team === "A") {
            let newAPoints = [...rosterAPoints]
            newAPoints[setNum] = point
            setRosterAPoints(newAPoints)
        } else {
            let newBPoints = [...rosterBPoints]
            newBPoints[setNum] = point
            setRosterBPoints(newBPoints)
        }
    }

    const renderPoints = (team: "A"|"B") => {
        let pointsArray = rosterAPoints
        if (team === "B") {
            pointsArray = rosterBPoints
        }
        return Array.from(Array(sets).keys()).map(i => (
            <NumberInput step={1} value={pointsArray[i]} min={0} key={i}
                         onChange={(valueAsString, valueAsNumber) => {
                             setPoint(valueAsNumber, i, team)
                         }}>
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
        ))
    }

    return (
        <div>
            Sets
            <RadioGroup onChange={onSetsChange}
                        value={String(sets)}>
                <Stack direction={'row'}>
                    <Radio value='1'>1</Radio>
                    <Radio value='2'>2</Radio>
                    <Radio value='3'>3</Radio>
                </Stack>
            </RadioGroup>
            <br />
            <div style={{float: 'left', width: '45%'}}>
                {game.rosterA.name}
                <br />
                {renderPoints("A")}
            </div>
            <div style={{float: 'right', width: '45%'}}>
                {game.rosterB.name}
                <br />
                {renderPoints("B")}
            </div>
            <div style={{clear: 'both'}} />
            <br />
            <Button colorScheme='blue' onClick={() => submitGameMutation.mutate()}>
                Submit
            </Button>
        </div>
    )
}

export default SubmitGameForm