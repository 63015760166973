import Hero from "./Hero"

const Landing = () => {
    return (
        <>
            <Hero />
            {/*<HeroImage />*/}
            {/*<HeroSplit />*/}
        </>
    )
}

export default Landing