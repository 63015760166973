import {Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import {RosterMemberGameStats} from "../../models/leagueModels";

interface GameStatsTableProps {
    stats: RosterMemberGameStats[]
}

const GameStatsTable = (props: GameStatsTableProps) => {
    const { stats } = props

    return (
        <Table variant={'striped'} size={'sm'}>
            <Thead>
                <Tr>
                    <Th>Player</Th>
                    <Th>Kills</Th>
                    <Th>Aces</Th>
                    <Th>Blocks</Th>
                    <Th>Assists</Th>
                </Tr>
            </Thead>
            <Tbody>
                {stats.map(stat => (
                    <Tr key={stat.id}>
                        <Td>{stat.rosterMember.player.firstName} ({stat.rosterMember.jerseyNumber})</Td>
                        <Td>{stat.kills}</Td>
                        <Td>{stat.aces}</Td>
                        <Td>{stat.assists}</Td>
                        <Td>{stat.blocks}</Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    )
}

export default GameStatsTable