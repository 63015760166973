import {
    Box,
    Button, Input, Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper,
    useDisclosure, VStack
} from "@chakra-ui/react";
import {useState} from "react";
import LeagueAPI from "../../api/leagueApi";
import {useMutation, useQueryClient} from "react-query";
import {useCustomToast} from "../../utils/toast";

interface AddRosterButtonProps {
    leagueId: number
}

const AddRosterButton = ({ leagueId }: AddRosterButtonProps) => {
    const [name, setName] = useState("")
    const [tier, setTier] = useState(1)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const queryClient = useQueryClient()
    const toast = useCustomToast()

    const createRoster = () => {
        return LeagueAPI.createRoster(leagueId, name, tier)
            .catch((error: any) => {
                console.log({error});
                toast({
                    title: 'Error',
                    description: 'Error creating roster.',
                    status: "error",
                })
            }).finally(()=>{
                onClose()
            })
    }

    const createRosterMutation = useMutation({
        mutationFn: () => createRoster(),
        onSuccess: () => queryClient.invalidateQueries(["league", leagueId])
    })

    return (
        <>
            <Button onClick={onOpen}>
                Add Roster
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Roster</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={6}>
                            <Box>
                                Name
                                <Input
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                    placeholder='Sinister Six...'
                                />
                            </Box>
                            <Box>
                                Starting Tier
                                <NumberInput step={1} value={tier} min={1} max={7}
                                             onChange={(_, valueAsNumber) => {
                                                 setTier(valueAsNumber)
                                             }}>
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </Box>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant='ghost' onClick={onClose}>Cancel</Button>
                        <Button colorScheme='blue' onClick={() => createRosterMutation.mutate()}>
                            Create
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default AddRosterButton