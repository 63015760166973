import {useState} from "react";
import {Editable, EditableInput, EditablePreview, Heading} from "@chakra-ui/react";
import {Tournament} from "../../models/Models";
import {TournamentAPI} from "../../api/tournamentApi";
import {useCustomToast} from "../../utils/toast";
import {useMutation, useQueryClient} from "react-query";


const EditTournamentName = (props: {tournament: Tournament}) => {
    const queryClient = useQueryClient()
    const toast = useCustomToast()
    const tournament = props.tournament
    const [name, setName] = useState(tournament.name)

    const updateNameMutation = useMutation({
        mutationFn: () => TournamentAPI
            .updateTournamentName(tournament.id, name)
            .catch(err => {
                console.log({err})
                toast({
                    title: 'Error',
                    description: 'Error saving tournament name.',
                    status: "error",
                })
            }),
        onSuccess: () => queryClient.invalidateQueries(["tournament", props.tournament.id])
    })

    return (
        <Heading size='xl'>
            <Editable value={name}
                      onChange={setName}
                      onSubmit={() => updateNameMutation.mutate()}
            >
                <EditablePreview />
                <EditableInput />
            </Editable>
        </Heading>
    )
}

export default EditTournamentName
