import {useParams} from "react-router-dom";
import {useContext} from "react";
import {UserContext} from "../../hooks/context";
import {useQuery} from "react-query";
import LeagueAPI from "../../api/leagueApi";
import {Container, Heading, Spinner, VStack} from "@chakra-ui/react";
import GameStatsTable from "./GameStatsTable";
import GameScoreTable from "./GameScoreTable";
import AddPlayerStatButton from "./AddPlayerStatButton";

const GameHome = () => {
    const params = useParams()
    const gameId = Number(params.gameId)
    const userContext = useContext(UserContext)
    const isAdmin = userContext?.user?.role === "ADMIN"

    const isValidId = !isNaN(gameId) && gameId > 0

    const gameQuery = useQuery({
        queryKey: ["game", gameId],
        queryFn: () => LeagueAPI.getGame(gameId),
        enabled: isValidId,
    })

    if (!isValidId) {
        return (
            <Container maxWidth={'5xl'}>
                <Heading size='lg'>
                    What are you up to here? Game Not found
                </Heading>
            </Container>
        )
    }

    if (gameQuery.isLoading) {
        return (
            <Container maxW={'5xl'} >
                <Heading size='xl'>
                    Loading...
                </Heading>
                <br />
                <Spinner size={'xl'} />
            </Container>
        )
    }

    if (!gameQuery.isSuccess) {
        return (
            <Container maxWidth={'5xl'}>
                <Heading size='lg'>
                    Game Not Found
                </Heading>
            </Container>
        )
    }

    let { league, game, stats, rosterAMembers, rosterBMembers } = gameQuery.data
    const statsAvailable = stats.length > 0
    const rosterAStats =
        stats.filter(stat => stat.rosterMember.rosterId === game.rosterAId)
    const rosterBStats =
        stats.filter(stat => stat.rosterMember.rosterId === game.rosterBId)


    return (
        <Container maxWidth={'5xl'}>
            <Heading size='xl' sx={{mb: 4}}>
                {game.rosterA.name} vs {game.rosterB.name}
            </Heading>
            <Heading size={'md'} sx={{mb: 4}}>
                Week {game.iteration} - {league.name}
            </Heading>
            <GameScoreTable game={game} />
            {statsAvailable && (
                <>
                    <Heading size={'sm'} sx={{mb: 6, mt: 10}}>
                        Stats - {game.rosterA.name}
                    </Heading>
                    <GameStatsTable stats={rosterAStats} />
                    <Heading size={'sm'} sx={{mb: 6, mt: 10}}>
                        Stats - {game.rosterB.name}
                    </Heading>
                    <GameStatsTable stats={rosterBStats} />
                </>
            )}
            {isAdmin && (
                <VStack spacing={4} sx={{mt: 4}}>
                    <AddPlayerStatButton gameId={game.id}
                                         memberOptions={rosterAMembers}
                                         rosterName={game.rosterA.name}
                    />
                    <AddPlayerStatButton gameId={game.id}
                                         memberOptions={rosterBMembers}
                                         rosterName={game.rosterB.name}
                    />
                </VStack>
            )}
        </Container>
    )
}

export default GameHome