import {RosterMember} from "../../models/leagueModels";
import {Heading, Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import {Link} from "react-router-dom";


interface RosterMemberListProps {
    rosterMembers: RosterMember[]
}

const RosterMemberList = (props: RosterMemberListProps) => {
    const { rosterMembers } = props
    return (
        <TableContainer>
            <Table variant='simple' size={'sm'}>
                <TableCaption placement={"top"}>
                    <Heading size={'xs'}>
                        Roster
                    </Heading>
                </TableCaption>
                <Thead>
                    <Tr>
                        <Th>Name</Th>
                        <Th>Jersey</Th>
                        <Th>Number</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {rosterMembers.map((rosterMember) => (
                        <Tr key={rosterMember.id}>
                            <Td>
                                <Link to={`/player/${rosterMember.playerId}`} style={{
                                    textDecoration:'underline'
                                }}>
                                    {rosterMember.player.firstName} {rosterMember.player.lastName}
                                </Link>
                            </Td>
                            <Td>{rosterMember.jerseyName}</Td>
                            <Td>{rosterMember.jerseyNumber}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    )
}

export default RosterMemberList