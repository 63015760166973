import {Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import {Game} from "../../models/leagueModels";

interface GameScoreTableProps {
    game: Game
}

const GameScoreTable = (props: GameScoreTableProps) => {
    const { game } = props
    return (
        <Table>
            <Thead>
                <Tr>
                    <Th>Team</Th>
                    {Array.from({length: game.sets}).map((_, i) =>
                        <Th>
                            Set {i + 1}
                        </Th>
                    )}
                </Tr>
            </Thead>
            <Tbody>
                <Tr>
                    <Td>{game.rosterA.name}</Td>
                    {game.rosterAPoints.map((points, i) =>
                        <Td key={i}>
                            {points}
                        </Td>
                    )}
                </Tr>
                <Tr>
                    <Td>{game.rosterB.name}</Td>
                    {game.rosterBPoints.map((points, i) =>
                        <Td key={i}>
                            {points}
                        </Td>
                    )}
                </Tr>
            </Tbody>
        </Table>
    )
}

export default GameScoreTable