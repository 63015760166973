import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay, Select,
    useDisclosure
} from "@chakra-ui/react";
import {useState} from "react";
import {Game} from "../../models/leagueModels";
import SubmitGameForm from "./SubmitGameForm";
import {stringToTime} from "../../utils/datetimeUtils";

interface SubmitGamesProps {
    games: Game[]
}

const SubmitGames = (props: SubmitGamesProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [game, setGame] = useState<Game | null>(null)
    const [tier, setTier] = useState(1)

    const gameOptions = props.games
        .filter(g => g.state === "SCHEDULED" && g.rosterA.tier === tier)
        .sort((a, b) => {
            const at = a.startsAt || ""
            const bt = b.startsAt || ""
            if (at < bt) return -1
            if (at === bt) return 0
            return 1
        })
        .map(g => (
            <option value={g.id} key={g.id}>
                [{stringToTime(g?.startsAt)}] {g.rosterA.name} vs {g.rosterB.name}
            </option>
        ))

    const tierOptions = Array.from(Array(5).keys()).map(idx =>
        <option value={idx+1} key={idx}>Tier {idx+1}</option>
    )

    const onChooseGame = (newGameId: number) => {
        let game = props.games.find(g => g.id === newGameId)
        setGame(game as Game)
    }

    return (
        <div>
            <Button onClick={onOpen} colorScheme={'purple'}>
                Submit Game Score
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Submit Game Score</ModalHeader>
                    <ModalBody>
                        <Select placeholder='Choose Tier' value={tier}
                                onChange={event => setTier(Number(event.target.value))}>
                            {tierOptions}
                        </Select>
                        <br />
                        {tier &&
                            <Select placeholder='Choose Game' value={game?.id}
                                    onChange={event => onChooseGame(Number(event.target.value))}>
                                {gameOptions}
                            </Select>
                        }
                        <br />
                        {game && <SubmitGameForm game={game} submitCallBack={onClose}/>}
                    </ModalBody>
                    <ModalFooter>
                        <Button variant='ghost' onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    )

}

export default SubmitGames
