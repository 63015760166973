import {
    Button,
    Container,
    Heading,
    useDisclosure,
} from "@chakra-ui/react";
import {useContext} from "react";
import TournamentList from "../tournament/TournamentList";
import {UserContext} from "../../hooks/context";
import Unauthorized from "../user/Unauthorized";
import CreateTournamentModal from "../tournament/CreateTournamentModal";


const AdminTournament = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const userContext = useContext(UserContext);

    if (userContext?.user?.role !== "ADMIN") {
        return <Unauthorized />
    }

    return (
        <Container maxW={'5xl'}>
            <Heading size='lg'>Tournaments List</Heading>
            <br />
            <TournamentList />
            <br />
            <Button onClick={onOpen}>
                Create Tournament
            </Button>
            <CreateTournamentModal isOpen={isOpen} onClose={onClose} />
        </Container>
    )
}


export default AdminTournament