import environment from "../utils/environment";
import Cookies from "js-cookie";
import axios from "axios";

class BaseBackendAPI {
    static apiUrl = environment.apiUrl

    static post = function(path: string, data: any) {
        const accessToken = Cookies.get('accessToken')
        let headers;
        if (accessToken) {
            headers = {
                'Authorization': `Bearer ${accessToken}`
            }
        } else {
            headers = {}
        }

        return axios.post(`${BaseBackendAPI.apiUrl}${path}`, data, {
            headers: headers
        })
    }

    static get = function(path: string) {
        const accessToken = Cookies.get('accessToken')
        let headers;
        if (accessToken) {
            headers = {
                'Authorization': `Bearer ${accessToken}`
            }
        } else {
            headers = {}
        }

        return axios.get(`${BaseBackendAPI.apiUrl}${path}`, {
            headers: headers
        })
    }

    static delete = function(path: string) {
        const accessToken = Cookies.get('accessToken')
        let headers;
        if (accessToken) {
            headers = {
                'Authorization': `Bearer ${accessToken}`
            }
        } else {
            headers = {}
        }

        return axios.delete(`${BaseBackendAPI.apiUrl}${path}`, {
            headers: headers
        })
    }
}

export default BaseBackendAPI