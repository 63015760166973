import {Button, Table, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import Environment from "../../utils/environment";
import {Link} from "react-router-dom"
import {useQuery} from "react-query";
import LeagueAPI from "../../api/leagueApi";

const LeagueList = () => {
    const leaguesQuery = useQuery({
        queryKey: ["league"],
        queryFn: () => LeagueAPI.getLeagues(),
    })

    const adminRoute = Environment.adminRoute

    if (leaguesQuery.isLoading || !leaguesQuery.isSuccess) {
        return <>Loading data...</>
    }

    const leagues = leaguesQuery.data.data

    let leagueRows = leagues.map(league=> (
        <Tr key={league.id}>
            <Td>{league.id}</Td>
            <Td>{league.name}</Td>
            <Td>{league.maxTeams}</Td>
            <Td>{league.numRegisteredTeams}</Td>
            <Td>{league.currentIteration}</Td>
            <Td>
                <Link to={`/${adminRoute}/league/${league.id}/manage`}>
                    <Button>
                        Manage
                    </Button>
                </Link>
            </Td>
        </Tr>
    ))

    if (leagues.length === 0) {
        return <>Nothing here... Create a league.</>
    }

    return (
        <TableContainer>
            <Table variant='simple'>
                <Thead>
                    <Tr>
                        <Th>ID</Th>
                        <Th>Name</Th>
                        <Th>Max Teams</Th>
                        <Th>Registered Teams</Th>
                        <Th>Iteration</Th>
                        <Th />
                    </Tr>
                </Thead>
                <Tbody>
                    {leagueRows}
                </Tbody>
            </Table>
        </TableContainer>
    )
}

export default LeagueList