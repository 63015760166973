import {useState} from "react";
import {
    Box,
    Button,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper, Radio, RadioGroup,
    Stack,
    useDisclosure,
    VStack
} from "@chakra-ui/react";
import {useMutation, useQueryClient} from "react-query";
import {useCustomToast} from "../../utils/toast";
import LeagueAPI from "../../api/leagueApi";
import {Gender} from "../../models/leagueModels";

interface CreateRosterMemberButtonProps {
    rosterId: number
    leagueId: number
}

const CreateRosterMemberButton = ({ rosterId, leagueId }: CreateRosterMemberButtonProps) => {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [jerseyNumber, setJerseyNumber] = useState(0)
    const [jerseyName, setJerseyName] = useState("")
    const [gender, setGender] = useState<Gender>("MALE")

    const { isOpen, onOpen, onClose } = useDisclosure()
    const queryClient = useQueryClient()
    const toast = useCustomToast()

    const createRosterMember = () => {
        return LeagueAPI.createRosterMember(rosterId, firstName, lastName, gender, jerseyNumber, jerseyName)
            .catch((error: any) => {
                console.log({error});
                toast({
                    title: 'Error',
                    description: 'Error creating roster member.',
                    status: "error",
                })
            }).finally(()=>{
                onClose()
            })
    }

    const createRosterMutation = useMutation({
        mutationFn: () => createRosterMember(),
        onSuccess: () => queryClient.invalidateQueries(["roster", rosterId])
    })

    return (
        <>
            <Button onClick={onOpen}>
                Add Member
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Roster</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={6}>
                            <Box>
                                First Name
                                <Input
                                    value={firstName}
                                    onChange={e => setFirstName(e.target.value)}
                                    placeholder='Noah'
                                />
                            </Box>
                            <Box>
                                Last Name
                                <Input
                                    value={lastName}
                                    onChange={e => setLastName(e.target.value)}
                                    placeholder='Noreska'
                                />
                            </Box>
                            <Box>
                                Jersey Name
                                <Input
                                    value={jerseyName}
                                    onChange={e => setJerseyName(e.target.value)}
                                    placeholder='Noreska'
                                />
                            </Box>
                            <Box>
                                Jersey Number
                                <NumberInput step={1} value={jerseyNumber} min={1} max={999}
                                             onChange={(_, valueAsNumber) => {
                                                 setJerseyNumber(valueAsNumber)
                                             }}>
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </Box>
                            <Box>
                                Gender
                                <RadioGroup onChange={(newVal: Gender) => setGender(newVal)}
                                            value={gender}>
                                    <Stack direction={'row'}>
                                        <Radio value='MALE'>Male</Radio>
                                        <Radio value='FEMALE'>Female</Radio>
                                    </Stack>
                                </RadioGroup>
                            </Box>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant='ghost' onClick={onClose}>Cancel</Button>
                        <Button colorScheme='blue' onClick={() => createRosterMutation.mutate()}>
                            Create
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default CreateRosterMemberButton