import {useParams} from "react-router-dom";
import {
    Button,
    Container,
    Heading,
    Text,
} from "@chakra-ui/react";
import EditTeams from "../team/EditTeams";
import EditTournamentName from "./EditTournamentName";
import DisplayPools from "../team/DisplayPools";
import {TournamentAPI} from "../../api/tournamentApi";
import {Stage} from "../../models/Models";
import PlayoffBrackets from "../matches/PlayoffBrackets";
import DisplayStandings from "../team/DisplayStandings";
import {getPoolsFromTeams} from "../../utils/format";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {UserContext} from "../../hooks/context";
import {useContext} from "react";
import Unauthorized from "../user/Unauthorized";
import SubmitPoolMatches from "../matches/SubmitPoolMatches";
import DisplayMatchesTable from "../matches/DisplayMatchesTable";

const TournamentManage = () => {
    const params = useParams()
    const queryClient = useQueryClient()
    const userContext = useContext(UserContext);

    const tournamentId = Number(params.tournamentId)
    const tournamentQuery = useQuery({
        queryKey: ["tournament", tournamentId],
        queryFn: () => TournamentAPI.getTournament(tournamentId),
    })

    const updateTournamentStageMutation = useMutation({
        mutationFn: (stage: Stage) => TournamentAPI.updateStage(tournamentId, stage),
        onSuccess: () => queryClient.invalidateQueries(["tournament", tournamentId])
    })

    if (userContext?.user?.role !== "ADMIN") {
        return <Unauthorized />
    }

    if (tournamentQuery.isLoading || !tournamentQuery.isSuccess) {
        return <>Loading...</>
    }

    const { tournament, teams, matches } = tournamentQuery.data
    const stage = tournament.stage
    const pools = getPoolsFromTeams(teams)

    if (stage === "PREP") {
        return (
            <Container maxW={'5xl'}>
                <EditTournamentName tournament={tournament} />
                <br />
                <EditTeams teams={teams} />
                <DisplayPools teams={teams} />
                <br />
                <br />
                <Button colorScheme='purple' size={'lg'}
                        onClick={()=>updateTournamentStageMutation.mutate(Stage.POOL)}>
                    Start Pool Play...
                </Button>
            </Container>
        )
    }

    if (stage === "POOL") {
        const poolGames = matches.filter(match => match.gameType === "POOL")
        const completedPoolGames = poolGames.filter(match => match.state === "DONE")
        const poolPlayCompleted = completedPoolGames.length === poolGames.length

        return (
            <Container maxW={'5xl'}>
                <Heading size='xl'>
                    {tournament.name}
                </Heading>
                <br />
                <DisplayPools teams={teams} />
                <br />
                <br />
                <SubmitPoolMatches matches={matches} pools={pools} />
                <br />
                <br />
                <Text>{completedPoolGames.length} of {poolGames.length} games completed</Text>
                <br />
                <DisplayMatchesTable tournamentId={tournamentId} matches={matches} isAdmin={true} />
                <br />
                <br />
                <br />
                {poolPlayCompleted &&
                    <Button colorScheme='purple' size={'lg'} disabled={true}
                            onClick={()=>updateTournamentStageMutation.mutate(Stage.PLAYOFF)}>
                        End Pool Play
                    </Button>
                }
            </Container>
        )
    }

    return (
        <div>
            <Heading size={'xl'}>
                {tournament.name}
            </Heading>
            <br /><br />
            <PlayoffBrackets tournament={tournament} isAdmin={true} matches={matches} />
            <Container maxW={'5xl'}>
                <Heading size={'lg'}>
                    Final Pool Play Standings
                </Heading>
                <br />
                <DisplayStandings tournamentId={tournamentId} pools={pools} />
            </Container>
        </div>
    )
}

export default TournamentManage
