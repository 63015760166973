enum Stage {
    PREP = "PREP",
    POOL = "POOL",
    PLAYOFF = "PLAYOFF",
    COMPLETE = "COMPLETE",
}

enum PlayoffFormat {
    POOL_MIX= "POOL_MIX",
    UNIFIED_SEEDING = "UNIFIED_SEEDING",
    DOUBLE_BRACKET = "DOUBLE_BRACKET",
}

interface Tournament {
    id: number
    name: string
    teamCount: number
    stage: Stage
    poolsCount: number
    playoffFormat: PlayoffFormat
}

enum GameType {
    POOL = "POOL",
    PREQUARTERS = "PREQUARTERS",
    QUARTERS = "QUARTERS",
    SEMIS = "SEMIS",
    FINALS = "FINALS",
    BRONZE = "BRONZE",
    CONSOLATION = "CONSOLATION",
}

enum WinnerTeam {
    A = "A",
    B = "B",
    TIE = "TIE",
}

enum MatchState {
    DONE = "DONE",
    SCHEDULED = "SCHEDULED",
    WALK_OVER = "WALK_OVER"
}

enum MatchBracket {
    GOLD = "GOLD",
    SILVER = "SILVER",
    BRONZE = "BRONZE",
    CONSOLATION = "CONSOLATION",
}

interface Match {
    id: number
    name: string
    tournamentId: number
    state: MatchState
    bracket?: MatchBracket
    teamAId: number
    teamA: Team
    teamBId: number
    teamB: Team
    teamAPoints: number[]
    teamBPoints: number[]
    sets: number
    winner: WinnerTeam
    gameType: GameType
    court?: string
    startsAt?: string
    refTeamId?: number
    refTeam?: Team
    nextMatchId: number | null
    nextLoserMatchId?: number
}

enum Pool {
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    E = "E",
    F = "F",
    G = "G",
}

interface Team {
    id: number
    tournamentId: number
    name: string
    pool: Pool
    poolIndex: number,
    seed: number
    finalRank: number
}

enum UserRole {
    STANDARD="STANDARD",
    ADMIN="ADMIN"
}

interface User {
    id: number
    username: string
    role: UserRole
}

export { Pool, Stage, GameType, PlayoffFormat, MatchBracket }
export type { Tournament, Team, WinnerTeam, User, UserRole, Match }

