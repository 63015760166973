import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import LeagueAPI from "../../api/leagueApi";
import {Container, Heading, Spinner} from "@chakra-ui/react";
import PlayerLeaguesTable from "./PlayerLeaguesTable";



const PlayerHome = () => {
    const params = useParams()
    const playerId = Number(params.playerId)

    const isValidId = !isNaN(playerId) && playerId > 0

    const playerQuery = useQuery({
        queryKey: ["player", playerId],
        queryFn: () => LeagueAPI.getPlayer(playerId),
        enabled: isValidId,
    })

    if (!isValidId) {
        return (
            <Container maxWidth={'5xl'}>
                <Heading size='lg'>
                    What are you up to here? Player Not found
                </Heading>
            </Container>
        )
    }

    if (playerQuery.isLoading) {
        return (
            <Container maxW={'5xl'} >
                <Heading size='xl'>
                    Loading...
                </Heading>
                <br />
                <Spinner size={'xl'} />
            </Container>
        )
    }

    if (!playerQuery.isSuccess) {
        return (
            <Container maxWidth={'5xl'}>
                <Heading size='lg'>
                    Player Not Found
                </Heading>
            </Container>
        )
    }

    let { player, rosterMembers } = playerQuery.data

    return (
        <Container maxWidth={'5xl'}>
            <Heading size='xl' sx={{mb: 4}}>
                {player.firstName} {player.lastName}
            </Heading>
            <Heading size={'sm'} sx={{mb: 2}}>
                Registered Leagues
            </Heading>
            <PlayerLeaguesTable rosterMembers={rosterMembers} />
        </Container>
    )
}


export default PlayerHome