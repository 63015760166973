import {Team} from "../../models/Models";
import {useState} from "react";
import {useCustomToast} from "../../utils/toast";
import {TournamentAPI, UpdateTeamData} from "../../api/tournamentApi";
import {Editable, EditableInput, EditablePreview, Text} from "@chakra-ui/react";
import {useMutation, useQueryClient} from "react-query";

const EditTeam = (props: { team: Team }) => {
    const queryClient = useQueryClient()
    const [team, setTeam] = useState<Team>(props.team)
    const toast = useCustomToast()

    const setName = (newName: string) => {
        let newTeam = {...team}
        newTeam.name = newName
        setTeam(newTeam)
    }

    const updateTeamMutation= useMutation({
        mutationFn: () => {
            const data: UpdateTeamData = {
                name: team.name,
            }
            return TournamentAPI
                .updateTeam(team.id, data)
                .then(res => {
                    setTeam(res.team)
                })
                .catch(err => {
                    console.log({err})
                    toast({
                        title: 'Error',
                        description: 'Error saving team data.',
                        status: "error",
                    })
                })
        },
        onSuccess: () => queryClient.invalidateQueries(["tournament", team.tournamentId])
    })

    return (
        <Text fontSize='lg'>
            <Editable value={team.name}
                      onChange={setName}
                      onSubmit={() => updateTeamMutation.mutate()}
            >
                <EditablePreview />
                <EditableInput />
            </Editable>
        </Text>
    )
}

const EditTeams = (props: { teams: Team[] }) => {
    return (
        <>
            {props.teams.map(team => (
                <div key={team.id}>
                    <EditTeam team={team} />
                </div>
            ))}
        </>
    )
}

export default EditTeams
