import {
    Container,
    Heading,
    Spinner,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {TournamentAPI} from "../../api/tournamentApi";
import {Team} from "../../models/Models";

const TeamHome = () => {
    const params = useParams()
    const teamId = Number(params.teamId)

    const isValidTeamId = !isNaN(teamId) && teamId > 0

    const teamQuery = useQuery({
        queryKey: ["team", teamId],
        queryFn: () => TournamentAPI.getTeam(teamId),
        enabled: isValidTeamId,
    })

    if (!isValidTeamId) {
        return (
            <Container maxWidth={'5xl'}>
                <Heading size='lg'>
                    What are you up to here? Team Not found
                </Heading>
            </Container>
        )
    }

    if (teamQuery.isLoading) {
        return (
            <Container maxW={'5xl'} >
                <Heading size='xl'>
                    Loading Team...
                </Heading>
                <br />
                <Spinner size={'xl'} />
            </Container>
        )
    }

    if (!teamQuery.isSuccess) {
        return (
            <Container maxWidth={'5xl'}>
                <Heading size='lg'>
                    Team Not Found
                </Heading>
            </Container>
        )
    }

    let { team, tournament, matches} = teamQuery.data

    const displayTeamName = (t: Team | null | undefined) => {
        if (!t) {
            return "TBD"
        }
        if (t.id === team.id) {
            return <b>{t.name}</b>
        }
        return t.name
    }

    return (
        <Container maxWidth={'5xl'}>
            <Heading size='xl'>
                Team: {team.name}
            </Heading>
            <br />
            <Heading size={'md'}>
                Tournament: {tournament.name}
            </Heading>
            <br />
            <TableContainer>
                <Table variant='simple'>
                    <TableCaption placement={"top"}>
                        <Heading size={'md'}>
                            Scheduled Matches
                        </Heading>
                    </TableCaption>
                    <Thead>
                        <Tr>
                            <Th>Start Time</Th>
                            <Th>Title</Th>
                            <Th>Team</Th>
                            <Th>Team</Th>
                            <Th>Ref</Th>
                            <Th>Scores</Th>
                            <Th>Court</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {matches.map(match => {
                            let startsAt = ''
                            if (match.startsAt) {
                                startsAt = (new Date(match.startsAt)).toLocaleTimeString()
                            }
                            return (
                                <Tr key={match.id}>
                                    <Td>{startsAt}</Td>
                                    <Td>{match.name}</Td>
                                    <Td>{displayTeamName(match.teamA)}</Td>
                                    <Td>{displayTeamName(match.teamB)}</Td>
                                    <Td>{displayTeamName(match.refTeam)}</Td>
                                    <Td>
                                        {Array.from(Array(match.sets).keys()).map(i => (
                                            <div key={i}>
                                                {match.teamAPoints[i]} - {match.teamBPoints[i]}
                                                <br />
                                            </div>
                                        ))}
                                    </Td>
                                    <Td>{match.court}</Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        </Container>
    )

}

export default TeamHome