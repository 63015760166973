import {Match} from "../../models/Models";
import {useState} from "react";
import {useMutation, useQueryClient} from "react-query";
import {TournamentAPI, UpdateMatchData} from "../../api/tournamentApi";
import {Button, Editable, EditableInput, EditablePreview} from "@chakra-ui/react";

interface EditMatchProps {
    match: Match
}
const EditMatch = (props: EditMatchProps) => {
    const { match } = props
    const [name, setName] = useState<string>(match.name || '')
    const [court, setCourt] = useState<string>(match.court || 'Set Court')

    let defaultStartsAt = ''
    if (match.startsAt) {
        let startsAtDateTime = new Date(match.startsAt)
        let hours = String(startsAtDateTime.getHours()).padStart(2, '0')
        let minutes = String(startsAtDateTime.getMinutes()).padEnd(2, '0')
        defaultStartsAt = `${hours}:${minutes}`
    }

    const [startsAtTime, setStartsAtTime] = useState<string>(defaultStartsAt)

    const queryClient = useQueryClient()

    const updateMatchMutation= useMutation({
        mutationFn: () => {
            let [hours, minutes] = startsAtTime.split(":")
            let startsAtDateTime = new Date(2023, 12, 21, Number(hours), Number(minutes))
            const data: UpdateMatchData = {
                name: name,
                court: court,
                startsAt: startsAtDateTime.toISOString()
            }
            return TournamentAPI
                .updateMatch(match.id, data)
                .catch(err => {
                    console.log({err})
                })
        },
        onSuccess: () => queryClient.invalidateQueries(["tournament", match.tournamentId])
    })

    return (
        <div>
            Title:
            <Editable value={name}
                      onChange={setName}>
                <EditablePreview />
                <EditableInput />
            </Editable>
            <br />
            Court:
            <Editable value={court}
                      onChange={setCourt}>
                <EditablePreview />
                <EditableInput />
            </Editable>
            <br />
            Start Time:
            <br />
            <input type="time" value={startsAtTime} onChange={e => setStartsAtTime(e.target.value)}/>
            <br />
            <br />
            <Button colorScheme='blue'
                    onClick={() => updateMatchMutation.mutate()}>
                Save
            </Button>
        </div>
    )
}

export default EditMatch