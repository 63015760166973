import {
    Box,
    Flex,
    HStack,
    IconButton,
    useDisclosure,
    useColorModeValue,
    Stack, Image,
} from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'
import BumpinLogo from "../logo/bumpin-logo-crop.png";
import {Link} from "react-router-dom";

interface NavItem {
    title: string
    to: string
}

interface NavLinkProps {
    navItem: NavItem
}

const Links: NavItem[] = [
    // { 'title': 'Leagues', 'to': '/live' }
]

const NavLink = (props: NavLinkProps) => {
    const { navItem } = props

    return (
        <Box
            px={2}
            py={1}
            rounded={'md'}
            _hover={{
                textDecoration: 'none',
                bg: useColorModeValue('gray.200', 'gray.700'),
            }}>
            <Link to={navItem.to}>
                {navItem.title}
            </Link>
        </Box>
    )
}

export default function Nav() {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4} sx={{mb: 6}}>
            <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                <IconButton
                    size={'md'}
                    icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                    aria-label={'Open Menu'}
                    display={{ md: 'none' }}
                    onClick={isOpen ? onClose : onOpen}
                />
                <HStack spacing={8} alignItems={'center'}>
                    <Link to={'/'}>
                        <Image src={BumpinLogo} sx={{width: '160px', marginTop: 1}} />
                    </Link>
                    <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
                        {Links.map((navItem) => (
                            <NavLink key={navItem.title} navItem={navItem} />
                        ))}
                    </HStack>
                </HStack>
            </Flex>

            {isOpen ? (
                <Box pb={4} display={{ md: 'none' }}>
                    <Stack as={'nav'} spacing={4}>
                        {Links.map((navItem) => (
                            <NavLink key={navItem.title} navItem={navItem} />
                        ))}
                    </Stack>
                </Box>
            ) : null}
        </Box>
    )
}
