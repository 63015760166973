import {Match, Tournament} from "../../models/Models";
import {Match as MatchComponent, SingleEliminationBracket,} from "@g-loot/react-tournament-brackets";
import {Match as MatchType, MatchComponentProps, Participant} from "@g-loot/react-tournament-brackets/dist/src/types";
import {
    Button, Modal, ModalBody,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure
} from "@chakra-ui/react";
import SubmitMatchForm from "./SubmitMatchForm";
import EditMatch from "./EditMatch";

interface RenderBracketProps {
    tournament: Tournament
    matches: Match[],
    isAdmin: boolean,
}

const roundHeaderTextGenerator = (currentRoundNumber: number, roundsTotalNumber: number) => {
    if (currentRoundNumber === roundsTotalNumber) {
        return "Finals"
    }

    if (currentRoundNumber === roundsTotalNumber - 1) {
        return "Semi Finals"
    }

    if (currentRoundNumber === roundsTotalNumber - 2) {
        return "Quarter Finals"
    }

    if (currentRoundNumber === roundsTotalNumber - 3) {
        return "Pre Quarters"
    }

    return `Round ${currentRoundNumber}`
}

const teamsToParticipants = (match: Match): Participant[] => {
    const { teamA, teamB } = match;
    if (!teamA) {
        return []
    }
    if (!teamB) {
        return [
            {
                id: teamA.id,
                name: teamA.name,
            }
        ]
    }
    if (!(match.state === "DONE")) {
        return [
            {
                id: teamA.id,
                name: teamA.name,
            },
            {
                id: teamB.id,
                name: teamB.name,
            },
        ]
    }

    const isWinnerTeam = (teamId: number) => {
        if (teamId === match.teamAId) {
            return (match.winner === "A")
        }
        return (match.winner === "B")
    }

    const teamResultText = (teamId: number) => {
        if (match.sets === 1) {
            if (teamId === match.teamAId) {
                return String(match.teamAPoints[0])
            }
            return String(match.teamBPoints[0])
        }

        let teamASetsWon = 0
        let teamBSetsWon = 0
        for (let i = 0; i < match.sets; i++) {
            if (match.teamAPoints[i] > match.teamBPoints[i]) {
                teamASetsWon++
            } else {
                teamBSetsWon++
            }
        }
        if (teamId === match.teamAId) {
            return String(teamASetsWon)
        }
        return String(teamBSetsWon)

    }

    return [
        {
            id: teamA.id,
            name: teamA.name,
            isWinner: isWinnerTeam(teamA.id),
            resultText: teamResultText(teamA.id)
        },
        {
            id: teamB.id,
            name: teamB.name,
            isWinner: isWinnerTeam(teamB.id),
            resultText: teamResultText(teamB.id)
        },
    ]

}

const generateMatches = (matches: Match[]) => {
    let matchesToRender: MatchType[] = matches.map(match => {
        let details = ''
        let name = match.name
        if (match.startsAt) {
            let startsAtDateTime = new Date(match.startsAt)
            details += startsAtDateTime.toLocaleTimeString() + ' '
        }
        if (match.court) {
            details += `@ Court ${match.court}`
        }

        if (match.refTeam) {
            name = `${match.name} (REF: ${match.refTeam.name})`
        }

        return {
            id: match.id,
            name: name,
            nextMatchId: match.nextMatchId,
            state: match.state,
            startTime: details,
            participants: teamsToParticipants(match),
            tournamentId: match.tournamentId,
            gameType: match.gameType,
            dbMatch: match,
        }
    })
    return matchesToRender
}

const ClickableMatchComponent = (props: MatchComponentProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const match = props.match.dbMatch

    const canAddScore = props.match.participants.length === 2 && !props.topWon && !props.bottomWon

    return (
        <>
            <MatchComponent {...props}
                onPartyClick={onOpen}
            />
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Game</ModalHeader>
                    <ModalBody>
                        <EditMatch match={match} />
                        <br />
                        {canAddScore && <SubmitMatchForm match={match} />}
                    </ModalBody>
                    <ModalFooter>
                        <Button variant='ghost' onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}


const RenderBracket = (props: RenderBracketProps) => {
    let matches = generateMatches(props.matches)

    let renderMatch = MatchComponent
    if (props.isAdmin) {
        renderMatch = ClickableMatchComponent
    }

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <SingleEliminationBracket
                matchComponent={renderMatch}
                matches={matches}
                options={{
                    style: {
                        roundHeader: {
                            roundTextGenerator: roundHeaderTextGenerator
                        }
                    }
                }}
            />
        </div>
    )
}

export default RenderBracket
