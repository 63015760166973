import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import LeagueAPI from "../../api/leagueApi";
import {Container, Heading, Spinner} from "@chakra-ui/react";
import PlayerIterationStatsTable from "./PlayerIterationStatsTable";


const PlayerLeagueHome = () => {
    const params = useParams()
    const playerId = parseInt(params.playerId || '')
    const leagueId = parseInt(params.leagueId || '')

    const isValidId = !isNaN(playerId) && playerId > 0 && !isNaN(leagueId) && leagueId > 0

    const playerQuery = useQuery({
        queryKey: ["player", playerId, "league", leagueId],
        queryFn: () => LeagueAPI.getPlayerLeague(playerId, leagueId),
        enabled: isValidId,
    })

    if (!isValidId) {
        return (
            <Container maxWidth={'5xl'}>
                <Heading size='lg'>
                    What are you up to here? 404 Not found
                </Heading>
            </Container>
        )
    }

    if (playerQuery.isLoading) {
        return (
            <Container maxW={'5xl'} >
                <Heading size='xl'>
                    Loading...
                </Heading>
                <br />
                <Spinner size={'xl'} />
            </Container>
        )
    }

    if (!playerQuery.isSuccess) {
        return (
            <Container maxWidth={'5xl'}>
                <Heading size='lg'>
                    404 Not Found
                </Heading>
            </Container>
        )
    }

    let { player, rosterMemberIterationStats, league } = playerQuery.data

    return (
        <Container maxWidth={'5xl'}>
            <Heading size='xl' sx={{mb: 4}}>
                {player.firstName} {player.lastName}
            </Heading>
            <Heading size={'sm'} sx={{mb: 2}}>
                {league.name}
            </Heading>

            <PlayerIterationStatsTable stats={rosterMemberIterationStats} />
        </Container>
    )
}


export default PlayerLeagueHome