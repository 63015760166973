import {
    Box,
    Button, Input, Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper,
    useDisclosure, VStack
} from "@chakra-ui/react";
import {useState} from "react";
import LeagueAPI from "../../api/leagueApi";
import {useMutation, useQueryClient} from "react-query";
import {useCustomToast} from "../../utils/toast";
import {Roster} from "../../models/leagueModels";
import RosterDropdown from "../roster/RosterDropdown";

interface AddGameButtonProps {
    leagueId: number
    rosters: Roster[]
}

const AddGameButton = ({ leagueId, rosters }: AddGameButtonProps) => {
    const defaultRosterId = rosters[0].id
    const [rosterAId, setRosterAId] = useState(defaultRosterId)
    const [rosterBId, setRosterBId] = useState(defaultRosterId)
    const [refRosterId, setRefRosterId] = useState(defaultRosterId)
    const [court, setCourt] = useState("1")
    const [iteration, setIteration] = useState(1)
    const [tier, setTier] = useState(1)
    const [startsAt, setStartsAt] = useState("00:00")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const queryClient = useQueryClient()
    const toast = useCustomToast()

    const createGame = () => {
        const startsAtISOString = (new Date(startsAt)).toISOString()
        return LeagueAPI.createGame(leagueId, rosterAId, rosterBId, court, startsAtISOString, refRosterId, iteration, tier)
            .catch((error: any) => {
                console.log({error});
                toast({
                    title: 'Error',
                    description: 'Error creating game.',
                    status: "error",
                })
            }).finally(()=>{
                onClose()
            })
    }

    const createGameMutation = useMutation({
        mutationFn: () => createGame(),
        onSuccess: () => queryClient.invalidateQueries(["league", leagueId])
    })

    return (
        <>
            <Button onClick={onOpen}>
                Create Game
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create Game</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={6}>
                            <Box>
                                Roster A
                                <RosterDropdown rosters={rosters} rosterId={rosterAId} setRosterId={setRosterAId} />
                            </Box>
                            <Box>
                                Roster B
                                <RosterDropdown rosters={rosters} rosterId={rosterBId} setRosterId={setRosterBId} />
                            </Box>
                            <Box>
                                Ref Roster
                                <RosterDropdown rosters={rosters} rosterId={refRosterId} setRosterId={setRefRosterId} />
                            </Box>
                            <Box>
                                Court
                                <Input
                                    value={court}
                                    onChange={e => setCourt(e.target.value)}
                                    placeholder='1...'
                                />
                            </Box>
                            <Box>
                                Week
                                <NumberInput step={1} value={iteration} min={1}
                                             onChange={(_, valueAsNumber) => {
                                                 setIteration(valueAsNumber)
                                             }}>
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </Box>
                            <Box>
                                Tier
                                <NumberInput step={1} value={tier} min={1}
                                             onChange={(_, valueAsNumber) => {
                                                 setTier(valueAsNumber)
                                             }}>
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </Box>

                            <Box>
                                Starts At
                                <br />
                                <input type="datetime-local" value={startsAt}
                                       onChange={e => {
                                           console.log(e.target.value)
                                           setStartsAt(e.target.value)}
                                       }/>
                            </Box>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant='ghost' onClick={onClose}>Cancel</Button>
                        <Button colorScheme='blue' onClick={() => createGameMutation.mutate()}>
                            Create
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}



export default AddGameButton