import {Match, Pool} from "../../models/Models";
import {useMutation, useQueryClient} from "react-query";
import {TournamentAPI} from "../../api/tournamentApi";
import {Button, Table, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";

interface DisplayMatchesTableProps {
    tournamentId: number
    matches: Match[]
    pool?: Pool | null
    isAdmin: boolean
}

const DisplayMatchesTable = (props: DisplayMatchesTableProps) => {
    const queryClient = useQueryClient()
    let matches = props.matches
    let pool = props.pool

    matches = matches
        .filter(match => match.state === "DONE")
    if (pool) {
        matches = matches.filter(match => match.gameType === "POOL" && match.teamA.pool === pool)
    }

    const unSubmitMatchMutation = useMutation({
        mutationFn: (matchId: number) => TournamentAPI
            .unSubmitMatch(matchId)
            .catch(err => {
                console.log({err});
            }),
        onSuccess: () => queryClient.invalidateQueries(["tournament", props.tournamentId])
    })

    const matchRows = matches.map(match => (
        <Tr key={match.id}>
            <Td>({match.teamA.pool}{match.teamA.poolIndex}) {match.teamA.name}</Td>
            <Td>({match.teamB.pool}{match.teamB.poolIndex}) {match.teamB.name}</Td>
            <Td>
                {Array.from(Array(match.sets).keys()).map(i => (
                    <div key={i}>
                        {match.teamAPoints[i]} - {match.teamBPoints[i]}
                        <br />
                    </div>
                ))}
            </Td>
            {props.isAdmin &&
                <Td>
                    <Button size={'sm'} colorScheme={'red'} onClick={() => unSubmitMatchMutation.mutate(match.id)}>
                        Delete
                    </Button>
                </Td>
            }
        </Tr>
    ))


    return (
        <TableContainer>
            <Table variant='simple'>
                <Thead>
                    <Tr>
                        <Th>Team</Th>
                        <Th>Team</Th>
                        <Th>Scores</Th>
                        {props.isAdmin && <Th />}
                    </Tr>
                </Thead>
                <Tbody>
                    {matchRows}
                </Tbody>
            </Table>
        </TableContainer>
    )
}


export default DisplayMatchesTable