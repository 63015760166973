import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {
    Box,
    Container,
    Heading,
    Spinner,
} from "@chakra-ui/react";
import LeagueAPI from "../../api/leagueApi";
import RosterMemberList from "./RosterMemberList";
import {useContext} from "react";
import {UserContext} from "../../hooks/context";
import CreateRosterMemberButton from "./CreateRosterMemberButton";
import PastGamesTable from "../game/PastGamesTable";
import UpcomingGamesTable from "../game/UpcomingGamesTable";

const RosterHome = () => {
    const params = useParams()
    const rosterId = Number(params.rosterId)
    const userContext = useContext(UserContext)
    const isAdmin = userContext?.user?.role === "ADMIN"

    const isValidId = !isNaN(rosterId) && rosterId > 0

    const rosterQuery = useQuery({
        queryKey: ["roster", rosterId],
        queryFn: () => LeagueAPI.getRoster(rosterId),
        enabled: isValidId,
    })

    if (!isValidId) {
        return (
            <Container maxWidth={'5xl'}>
                <Heading size='lg'>
                    What are you up to here? Roster Not found
                </Heading>
            </Container>
        )
    }

    if (rosterQuery.isLoading) {
        return (
            <Container maxW={'5xl'} >
                <Heading size='xl'>
                    Loading...
                </Heading>
                <br />
                <Spinner size={'xl'} />
            </Container>
        )
    }

    if (!rosterQuery.isSuccess) {
        return (
            <Container maxWidth={'5xl'}>
                <Heading size='lg'>
                    Roster Not Found
                </Heading>
            </Container>
        )
    }

    let { roster, league, rosterMembers, games } = rosterQuery.data
    const scheduledGames = games.filter(game => game.state === "SCHEDULED")

    return (
        <Container maxWidth={'5xl'}>
            <Heading size='xl' sx={{mb: 4}}>
                {roster.name}
            </Heading>
            <Heading size={'md'} sx={{mb: 4}}>
                {league.name}
            </Heading>
            <Heading size={'sm'}>
                Current Tier: {roster.tier}
            </Heading>
            <Box sx={{mb: 4}}>
                <RosterMemberList rosterMembers={rosterMembers} />
            </Box>
            {isAdmin && <CreateRosterMemberButton rosterId={roster.id} leagueId={league.id} />}
            {scheduledGames.length > 0 && (
                <div>
                    <Heading size={'sm'} sx={{mt: 10, mb: 4}}>
                        Upcoming Games
                    </Heading>
                    <UpcomingGamesTable
                        targetRoster={roster}
                        games={scheduledGames}
                    />
                </div>
            )}
            <Heading size={'sm'} sx={{mt: 10}}>
                Past Games
            </Heading>
            <PastGamesTable
                targetRoster={roster}
                games={games.filter(game => game.state === "DONE" && game.refRosterId !== roster.id)}
            />
        </Container>
    )
}

export default RosterHome