export const stringToTime = (dtstr?: string | null) => {
    if (!dtstr) {
        return ''
    }
    let date = new Date(dtstr)
    return date.toLocaleTimeString()
}

export const formatDtStrToDateTime = (dtstr: string) => {
    return new Date(dtstr).toLocaleString('en-us', {
        "month": "short",
        "day": "numeric",
        "hour12": true,
        "hour": "numeric",
        "minute": "2-digit"
    })
}

export const formatDtStrToDate = (dtstr: string) => {
    return new Date(dtstr).toLocaleDateString('en-us', {
        "month": "short",
        "day": "numeric",
    })
}
