import {TournamentAPI} from "../../api/tournamentApi";
import {Button, Table, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import Environment from "../../utils/environment";
import {Link} from "react-router-dom"
import {useQuery} from "react-query";

const TournamentList = () => {
    const tournamentsQuery = useQuery({
        queryKey: ["tournament"],
        queryFn: () => TournamentAPI.getTournaments(),
    })

    const adminRoute = Environment.adminRoute

    if (tournamentsQuery.isLoading || !tournamentsQuery.isSuccess) {
        return <>Loading data...</>
    }

    const tournaments = tournamentsQuery.data.data

    let tournamentRows = tournaments.map(tournament=> (
        <Tr key={tournament.id}>
            <Td>{tournament.id}</Td>
            <Td>{tournament.name}</Td>
            <Td>{tournament.teamCount}</Td>
            <Td>{tournament.poolsCount}</Td>
            <Td>{tournament.stage}</Td>
            <Td>
                <Link to={`/${adminRoute}/tournament/${tournament.id}/manage`}>
                    <Button>
                        Manage
                    </Button>
                </Link>
            </Td>
        </Tr>
    ))

    if (tournaments.length === 0) {
        return <>Nothing here... Create a tournament.</>
    }

    return (
        <TableContainer>
            <Table variant='simple'>
                <Thead>
                    <Tr>
                        <Th>ID</Th>
                        <Th>Name</Th>
                        <Th>Team Count</Th>
                        <Th>Pools Count</Th>
                        <Th>Stage</Th>
                        <Th />
                    </Tr>
                </Thead>
                <Tbody>
                    {tournamentRows}
                </Tbody>
            </Table>
        </TableContainer>
    )
}

export default TournamentList