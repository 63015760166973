import {
    Container,
    Heading,
    Select,
    Spinner,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import {useQuery} from "react-query";
import {TournamentAPI} from "../../api/tournamentApi";
import {ChangeEvent} from "react";
import {Pool} from "../../models/Models";
import {useSearchParams} from "react-router-dom";
import {TeamRecord} from "../../api/responses";

interface DisplayStandingsProps {
    tournamentId: number
    pools: Pool[]
}

const RenderPoolStandings = (props: { pool: Pool, rankedTeams: TeamRecord[] }) => {
    const { pool, rankedTeams } = props;
    return (
        <TableContainer>
            <Table variant='simple'>
                <TableCaption placement={"top"}>
                    <Heading size={'md'}>
                        Pool {pool}
                    </Heading>
                </TableCaption>
                <Thead>
                    <Tr>
                        <Th>Rank</Th>
                        <Th>Team Name</Th>
                        <Th>Sets Won</Th>
                        <Th>Points Diff</Th>
                        <Th>Sets Played</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {rankedTeams.map((teamRecordData, rank) => (
                        <Tr key={teamRecordData.team.id}>
                            <Td>{rank+1}</Td>
                            <Td>({teamRecordData.team.pool}{teamRecordData.team.poolIndex}) {teamRecordData.team.name}</Td>
                            <Td>{teamRecordData.setsWon}</Td>
                            <Td>{teamRecordData.pointsDiff}</Td>
                            <Td>{teamRecordData.setsPlayed}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    )
}


const DisplayStandings = (props: DisplayStandingsProps) => {
    const { tournamentId, pools } = props

    let [searchParams, setSearchParams] = useSearchParams()

    const standingsQuery = useQuery({
        queryKey: ["tournament", tournamentId, "standings"],
        queryFn: () => TournamentAPI.getStandings(tournamentId)
    })

    if (standingsQuery.isLoading || !standingsQuery.isSuccess) {
        return (
            <Spinner size={'xl'} />
        )
    }

    const poolToSortedTeams = standingsQuery.data.data

    if (pools.length <= 4) {
        const standingTables = Object.keys(poolToSortedTeams).sort().map(curPool => {

            const rankedTeams = poolToSortedTeams[curPool]
            return (
                <div key={curPool}>
                    <RenderPoolStandings pool={curPool as Pool} rankedTeams={rankedTeams} />
                    <br />
                    <br />
                </div>
            )
        })
        return (
            <div>
                {standingTables}
            </div>
        )
    }

    let pool: Pool | null = searchParams.get('pool') as Pool
    if (!pools.includes(pool)) {
        pool = pools[0]
    }

    const poolOptions = pools.map(curPool =>
        <option value={curPool} key={curPool}>Pool {curPool}</option>
    )
    const rankedTeams = poolToSortedTeams[pool]
    const standingsTable = <RenderPoolStandings pool={pool} rankedTeams={rankedTeams} />

    const onSelectPoolChange = (event: ChangeEvent<HTMLSelectElement>) => {
        searchParams.set('pool', event.target.value)
        setSearchParams(searchParams)
    }

    return (
        <div>
            <Container size={'xs'}>
                <Select placeholder='Choose Pool' value={pool as string} onChange={onSelectPoolChange}>
                    {poolOptions}
                </Select>
            </Container>
            {standingsTable}
        </div>
    )
}

export default DisplayStandings
