import {useParams} from "react-router-dom";
import {
    Box,
    Container,
    Heading, VStack,
} from "@chakra-ui/react";
import {useQuery} from "react-query";
import {UserContext} from "../../hooks/context";
import {useContext} from "react";

import LeagueAPI from "../../api/leagueApi";
import RosterList from "../roster/RosterList";
import AddRosterButton from "../roster/AddRosterButton";
import AddGameButton from "../game/AddGameButton";
import GenerateGamesButton from "../../components/admin/GenerateGamesButton";
import GenerateStandingsButton from "../../components/admin/GenerateStandingsButton";
import SubmitGames from "../../components/admin/SubmitGames";
import EndIterationButton from "../../components/admin/EndIterationButton";

interface LeagueHomeProps {
    leagueId?: number
}

const LeagueHome = ({ leagueId: propsLeagueId }: LeagueHomeProps) => {
    const params = useParams()
    const userContext = useContext(UserContext);

    const leagueId = propsLeagueId || Number(params.leagueId)
    const leagueQuery = useQuery({
        queryKey: ["league", leagueId],
        queryFn: () => LeagueAPI.getLeague(leagueId),
    })

    const isAdmin = userContext?.user?.role === "ADMIN"

    if (leagueQuery.isLoading || !leagueQuery.isSuccess) {
        return <>Loading...</>
    }

    const { league, rosters, games } = leagueQuery.data

    return (
        <Container maxW={'5xl'}>
            <Heading size={'xl'} sx={{mb: 2}}>
                {league.name}
            </Heading>
            <Heading size={'md'} sx={{mb: 4}}>
                Week {league.currentIteration}
            </Heading>
            <Heading size={'m'}>
                Teams
            </Heading>
            <Box sx={{mb: 7}}>
                <RosterList rosters={rosters} />
            </Box>
            {isAdmin && (
                <VStack spacing={6}>
                    <AddRosterButton leagueId={league.id} />
                    {rosters.length > 0 && <AddGameButton leagueId={league.id} rosters={rosters} />}
                    <GenerateGamesButton leagueId={league.id} />
                    <GenerateStandingsButton leagueId={league.id} />
                    <SubmitGames games={games} />
                    <EndIterationButton leagueId={league.id} />
                </VStack>
            )}

        </Container>
    )
}

export default LeagueHome
