import {
    createTournamentResponse,
    GetStandingsResponse, GetTeamResponse,
    getTournamentResponse,
    getTournamentsResponse, SubmitMatchResponse, unSubmitMatchResponse, UpdateMatchResponse,
    UpdateTeamResponse,
    UpdateTournamentResponse,
    UpdateTournamentStageResponse
} from "./responses";
import {Pool, Stage} from "../models/Models";
import BaseBackendAPI from "./backendApi";

interface UpdateTeamData {
    name?: string
    pool?: Pool
    seed?: number
}

interface UpdateMatchData {
    name?: string
    refTeamId?: number
    court?: string
    startsAt?: string
}

class TournamentAPI extends BaseBackendAPI {
    static getTournament = (id: number | string) => {
        return this.get(`/tournament/${id}`)
            .then<getTournamentResponse>(res => res.data)
    }

    static getTournaments = () => {
        return this.get(`/tournament`)
            .then<getTournamentsResponse>(res => res.data)
    }

    static createTournament = (name: string, teamCount: number, poolsCount: number) => {
        return this.post(`/tournament`, {
            tournament: {name, teamCount, poolsCount}
        })
            .then<createTournamentResponse>(res => res.data)
    }

    static updateTournamentName = (id: number, name: string) => {
        return this.post(`/tournament/${id}/update`, {
            name: name,
        })
            .then<UpdateTournamentResponse>(res => res.data)
    }

    static updateStage = (id: number, stage: Stage) => {
        return this.post(`/tournament/${id}/update-stage`, {
            stage: stage,
        })
            .then<UpdateTournamentStageResponse>(res => res.data)
    }

    static updateTeam = (teamId: number, data: UpdateTeamData) => {
        return this.post(`/team/${teamId}/update`, {
            data,
        })
            .then<UpdateTeamResponse>(res => res.data)
    }

    static updateMatch = (matchId: number, data: UpdateMatchData) => {
        return this.post(`/match/${matchId}/update`, {
            data,
        })
            .then<UpdateMatchResponse>(res => res.data)
    }

    static submitMatch = (matchId: number,
                          teamAPoints: number[],
                          teamBPoints: number[],
                          sets: number) => {
        const postData = {
            teamAPoints,
            teamBPoints,
            sets,
        }
        return this.post(`/match/${matchId}/submit`, postData)
            .then<SubmitMatchResponse>(res => res.data)
    }

    static unSubmitMatch = (matchId: number) => {
        return this.post(`/match/${matchId}/unsubmit`, {})
            .then<unSubmitMatchResponse>(res => res.data)
    }

    static getStandings = (id: number | string) => {
        return this.get(`/tournament/${id}/standings`)
            .then<GetStandingsResponse>(res => res.data)
    }

    static getTeam = (teamId: number | string) => {
        return this.get(`/team/${teamId}`)
            .then<GetTeamResponse>(res => res.data)
    }
}

export type { UpdateTeamData, UpdateMatchData }
export { TournamentAPI }

