import {Team} from "../../models/Models";
import {
    Heading,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";

interface DisplayPoolsProps {
    teams: Team[]
}

function transposeArray<T>(array: T[][]) :T[][] {
    return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
}

function padMatrix<T>(array: (T | null)[][]) {
    let maxLen = 0
    for (const row of array) {
        maxLen = Math.max(maxLen, row.length)
    }
    for (const row of array) {
        while (row.length < maxLen) {
            row.push(null)
        }
    }
}

const DisplayPools = (props: DisplayPoolsProps) => {
    let poolTeamMap: { [pool: string]: Team[] } = {}

    props.teams.forEach(team => {
        if (Object.keys(poolTeamMap).includes(team.pool)) {
            poolTeamMap[team.pool].push(team)
        } else {
            poolTeamMap[team.pool] = [team]
        }
    })

    let pools = Object.keys(poolTeamMap).sort()
    let headers = pools.map(pool => (
        <Th key={pool}>
            Pool {pool}
        </Th>
    ))

    let displayMatrix = pools.map(pool => poolTeamMap[pool])

    padMatrix(displayMatrix)
    const teamsTable = transposeArray(displayMatrix)

    return (
        <TableContainer>
            <Table variant='simple'>
                <TableCaption placement={"top"}>
                    <Heading size={'lg'}>
                        Pools
                    </Heading>
                    {/*<br />*/}
                    {/*<Heading size={'sm'}>*/}
                    {/*    Click on your team to see your schedule*/}
                    {/*</Heading>*/}
                </TableCaption>
                <Thead>
                    <Tr>
                        {headers}
                    </Tr>
                </Thead>
                <Tbody>
                    {teamsTable.map((row, idx) => (
                        <Tr key={idx}>
                            {row.map((team, idx) => (
                                <Td key={idx}>
                                    {team && `${team.pool}${team.poolIndex}: ${team.name}`
                                        // <Link to={`/team/${team.id}`} style={{
                                        //     textDecoration:'underline'
                                        // }}>
                                        //     {team.pool}{team.poolIndex}: {team.name}
                                        // </Link>
                                    }
                                </Td>
                            ))}
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    )
}

export default DisplayPools