import {
    Button
} from "@chakra-ui/react";
import LeagueAPI from "../../api/leagueApi";
import {useMutation, useQueryClient} from "react-query";
import {useCustomToast} from "../../utils/toast";

interface GenerateGamesButtonProps {
    leagueId: number
}

const GenerateGamesButton = ({ leagueId }: GenerateGamesButtonProps) => {
    const toast = useCustomToast()
    const queryClient = useQueryClient()

    const generateGames = () => {
        return LeagueAPI.generateGames(leagueId)
            .catch((error: any) => {
                console.log({error});
                toast({
                    title: 'Error',
                    description: 'Error generating games.',
                    status: "error",
                })
            })
    }

    const generateGamesMutation = useMutation({
        mutationFn: () => generateGames(),
        onSuccess: () => queryClient.invalidateQueries(["league", leagueId])
    })

    return (
        <Button colorScheme={'green'} onClick={() => generateGamesMutation.mutate()}>
            Generate Games
        </Button>
    )
}

export default GenerateGamesButton