import {Image, Container, Heading, Spinner} from "@chakra-ui/react";
import DisplayPools from "../team/DisplayPools";
import DisplayStandings from "../team/DisplayStandings";
import PlayoffBrackets from "../matches/PlayoffBrackets";
import {TournamentAPI} from "../../api/tournamentApi";
import {useQuery} from "react-query";
import DisplaySeeding from "../team/DisplaySeeding";
import {getPoolsFromTeams} from "../../utils/format";
import {useSearchParams} from "react-router-dom";
import {Pool} from "../../models/Models";
import DisplayMatchesTable from "../matches/DisplayMatchesTable";

// async function importImage(tournamentId: number) {
//     try {
//         let path = `./src/images/schedule-${tournamentId}.png`
//         const image = await import(path);
//         return image
//     } catch (error) {
//         console.error('import failed');
//         return null
//     }
// }


interface TournamentDisplayProps {
    tournamentId?: number
    scheduleImage?: any
}

const TournamentDisplay = (tournamentDisplayProps: TournamentDisplayProps) => {
    const { tournamentId } = tournamentDisplayProps

    const tournamentQuery = useQuery({
        queryKey: ["tournament", tournamentId],
        queryFn: () => TournamentAPI.getTournament(tournamentId as number),
        enabled: !!tournamentId
    })

    let searchParams = useSearchParams()[0];

    const containerMaxWidth = '3xl'

    if (!tournamentId) {
        return (
            <Container maxW={containerMaxWidth} >
                <Heading size='xl'>
                    Volleyball Tournament
                </Heading>
                <br />
                <br />
                <br />
                <Heading>🛠️ Under construction, come back later 🛠️</Heading>
            </Container>
        )
    }

    let centeredScheduleImg = (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            objectFit: "contain",
        }}>
            <Image src={tournamentDisplayProps.scheduleImage} />
        </div>
    )

    if (tournamentQuery.isLoading || !tournamentQuery.isSuccess) {
        return (
            <Container maxW={'5xl'} >
                <Heading size='xl'>
                    LOADING...
                </Heading>
                <br />
                <br />
                {centeredScheduleImg}
                <br />
                <br />
                <Spinner size={'xl'} />
            </Container>
        )
    }

    const { tournament, teams, matches } = tournamentQuery.data
    const stage = tournament.stage
    const pools = getPoolsFromTeams(teams)
    let pool: Pool | null = searchParams.get('pool') as Pool
    if (!pools.includes(pool)) {
        pool = null
    }

    if (stage === "PREP") {
        return (
            <Container maxW={containerMaxWidth} >
                <Heading size='xl'>
                    {tournament.name}
                </Heading>
                <br />
                <DisplayPools teams={teams} />
                <br />
                <br />
                {centeredScheduleImg}
                <br />
                <br />
                <Heading>🛠️ Stay tuned until the tournament starts 🛠️</Heading>
            </Container>
        )
    }

    if (stage === "POOL") {
        return (
            <Container maxW={containerMaxWidth}>
                <Heading size='xl'>
                    {tournament.name}
                </Heading>
                <br />
                <DisplayPools teams={teams} />
                <br />
                <br />
                {centeredScheduleImg}
                <br />
                <br />
                <Heading size={'lg'}>
                    Live Pool Standings
                </Heading>
                <br />
                <DisplayStandings tournamentId={tournamentId} pools={pools} />
                <br />
                <Heading size={'md'}>
                    Scores List
                </Heading>
                <br />
                <DisplayMatchesTable tournamentId={tournamentId} matches={matches} pool={pool} isAdmin={false} />
            </Container>
        )
    }

    return (
        <div>
            <Heading size={'xl'}>
                {tournament.name}
            </Heading>
            <br />
            <Container maxW={containerMaxWidth}>
                <DisplayPools teams={teams} />
                <br />
                <br />
                {centeredScheduleImg}
            </Container>
            <br />
            <br />
            <PlayoffBrackets tournament={tournament} isAdmin={false} matches={matches} />
            <br />
            <Container maxW={containerMaxWidth}>
                <DisplaySeeding tournament={tournament} teams={teams} />
                <br />
                <br />
                <Heading size={'lg'}>
                    Final Pool Play Standings
                </Heading>
                <br />
                <DisplayStandings tournamentId={tournamentId} pools={pools} />
            </Container>
        </div>
    )
}

export default TournamentDisplay