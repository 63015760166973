import {
  Box,
  Heading,
  Container,
  Text,
  Button,
  Stack,
} from '@chakra-ui/react';
import { mainColor } from '../../constants';
import {Link} from "react-router-dom"

export default function CallToActionWithAnnotation() {


  return (
    <>
      <Container maxW={'3xl'}>
        <Stack
          as={Box}
          textAlign={'center'}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}>
          <Heading
            fontWeight={600}
            fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
            lineHeight={'110%'}>
            Bumpin 
            <Text as={'span'} color={mainColor}>
              Sports
            </Text>
          </Heading>
          <Text>
            Volleyball leagues and tournaments in the GTA.
          </Text>
          <Stack
            direction={'column'}
            spacing={3}
            align={'center'}
            alignSelf={'center'}
            position={'relative'}>
            <Link to={'/live'}>
              <Button
                  colorScheme={'orange'}
                  rounded={'full'}
              >
                LIVE - August 17 Mens
              </Button>
            </Link>
            <Link to={'/league/summer-2024'}>
              <Button
                  colorScheme={'pink'}
                  bg={mainColor}
                  rounded={'full'}
                  px={6}
                  _hover={{
                    bg: "pink.400",
                  }}>
                Summer League Info
              </Button>
            </Link>
            {/*<Button variant={'link'} colorScheme={'blue'} size={'sm'}>*/}
            {/*  Past Events*/}
            {/*</Button>*/}
          </Stack>
        </Stack>
      </Container>
    </>
  );
}
