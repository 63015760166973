import {Roster} from "../../models/leagueModels";
import {Table, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import {Link} from "react-router-dom";


interface RosterListProps {
    rosters: Roster[]
}

const RosterList = (props: RosterListProps) => {
    const { rosters } = props
    return (
        <TableContainer>
            <Table variant='simple'>
                <Thead>
                    <Tr>
                        <Th>Name</Th>
                        <Th>Current Tier</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {rosters.sort((a, b) => a.tier - b.tier).map((roster) => (
                        <Tr key={roster.id}>
                            <Td>
                                <Link to={`/roster/${roster.id}`} style={{
                                        textDecoration:'underline'
                                    }}>
                                    {roster.name}
                                </Link>
                            </Td>
                            <Td>{roster.tier}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    )
}

export default RosterList