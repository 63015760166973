import {Game, Roster} from "../../models/leagueModels";
import {Table, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import {formatDtStrToDateTime} from "../../utils/datetimeUtils";

interface UpcomingGamesTableProps {
    targetRoster: Roster
    games: Game[]
}

const UpcomingGamesTable = ({ games, targetRoster }: UpcomingGamesTableProps) => {
    const gameRows = games
        .sort((a, b) => {
            if (a.startsAt < b.startsAt) return -1
            if (a.startsAt === b.startsAt) return 0
            return 1
        })
        .map((game) => (
        <Tr key={game.id}>
            <Td>{formatDtStrToDateTime(game.startsAt)}</Td>
            <Td>
                {game.rosterAId === targetRoster.id ? game.rosterB.name
                    : game.rosterBId === targetRoster.id ? game.rosterA.name
                        : 'N/A - Reffing'}
            </Td>
        </Tr>
    ))

    return (
        <TableContainer>
            <Table variant='simple' size={'sm'}>
                <Thead>
                    <Tr>
                        <Th>Date</Th>
                        <Th>VS</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {gameRows}
                </Tbody>
            </Table>
        </TableContainer>
    )
}

export default UpcomingGamesTable
