import {RosterMemberIterationStats} from "../../models/leagueModels";
import {Heading, Table, TableCaption, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";

interface PlayerIterationStatsTableProps {
    stats: RosterMemberIterationStats[]
}

const PlayerIterationStatsTable = (props: PlayerIterationStatsTableProps) => {
    const { stats } = props

    return (
        <Table variant={'striped'} size={'sm'}>
            <TableCaption placement={"top"}>
                <Heading size={'sm'}>
                    Weekly Stats
                </Heading>
            </TableCaption>
            <Thead>
                <Tr>
                    <Th>Week</Th>
                    <Th>Kills</Th>
                    <Th>Blocks</Th>
                    <Th>Aces</Th>
                    <Th>Assists</Th>
                </Tr>
            </Thead>
            <Tbody>
                {stats.map(st => (
                    <Tr key={st.id}>
                        <Td>{st.iteration}</Td>
                        <Td>{st.kills}</Td>
                        <Td>{st.blocks}</Td>
                        <Td>{st.aces}</Td>
                        <Td>{st.assists}</Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    )
}

export default PlayerIterationStatsTable

