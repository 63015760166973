import {Button, Container, Heading, useDisclosure} from "@chakra-ui/react";
import LeagueList from "../league/LeagueList";
import Unauthorized from "../user/Unauthorized";
import {useContext} from "react";
import {UserContext} from "../../hooks/context";
import CreateLeagueModal from "../league/CreateLeagueModal";

const AdminLeague = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const userContext = useContext(UserContext);

    if (userContext?.user?.role !== "ADMIN") {
        return <Unauthorized />
    }

    return (
        <Container maxW={'5xl'}>
            <Heading size='lg'>Leagues List</Heading>
            <br />
            <LeagueList />
            <br />
            <Button onClick={onOpen}>
                Create League
            </Button>
            <CreateLeagueModal isOpen={isOpen} onClose={onClose} />
        </Container>
    )
}

export default AdminLeague
