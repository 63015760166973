import Nav from "./Nav";
import {Box} from "@chakra-ui/react";
import {Outlet} from "react-router-dom";
import Footer from "./Footer";

const Root = () => {
    return (
        <Box textAlign={'center'}>
            <Nav />
            <Outlet />
            <Footer />
        </Box>
    )
}

export default Root